import "./styles.scss";
import { useState } from "react";
import NormalBtn from "../../components/Common/Buttons/NormalBtn";
import SmallLoading from "../../components/Common/SmallLoading";
import MainCard from "../../components/Feature/Cards/MainCard";
import MenuForm from "../../components/Feature/Forms/MenuForm";
import useUploadImmage from "../../components/Feature/Upload/useUploadImage";
import Container from "../../components/Layout/Container";
import useGetPlanos from "../Plans/api/useGetPlanos";
import { toast } from "react-toastify";
import useUploadImageCardapio from "./api/useUploadImageCardapio";
import CardRightCardapio from "./components/cardsRight/CardRightCardapio";

const Cardapio = () => {
  const { planos, isGettingPlanos } = useGetPlanos();
  const [selectedPLan, setSelectedPLan] = useState();

  const { uploadImageCardapio, isUploadingCardapioImage } =
    useUploadImageCardapio();

  const handleUpload = (image) => {
    if (!selectedPLan) {
      toast.error("Selecione um plano", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    uploadImageCardapio(
      {
        image: image,
        id_plano: selectedPLan,
      },
      {
        onSuccess(data) {
          console.log(data);
          setImage(null);
          toast.success("Cardapio envidado com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(error) {
          console.log(error);
          toast.error(
            "Erro ao enviar imagem verifique se a imagem é menor ou igual a 2MB",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        },
      }
    );
  };

  const {
    image,
    handleSave,
    setImage,
    handleImageChange,
    handleUploadLocal,
    handleDeleteImage,
  } = useUploadImmage({ upload: handleUpload });

  return (
    <Container
      title={"Cardápio"}
      showCard={true}
      notShowCloseBtn={true}
      descCardContent={<CardRightCardapio />}
    >
      <MainCard
        notShowInputSearch
        headerClassName={"justify-content-end pb-2"}
        cardLabel={
          <>
            <div
              className="d-flex justify-content-end
      "
            >
              <div style={{ width: "500px", marginRight: "0.62rem" }}>
                {isGettingPlanos ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0.65rem 0",
                      width: "500px",
                    }}
                    className="form-control"
                  >
                    <SmallLoading />
                  </div>
                ) : (
                  <select
                    id="bairro"
                    className="form-select select-cardapio"
                    onChange={(e) => setSelectedPLan(e.target.value)}
                  >
                    <option>Selecione o plano</option>
                    {planos?.map((item) => (
                      <option value={item.id}>{item.nome}</option>
                    ))}
                    <option value="no-plan">Sem Plano</option>
                  </select>
                )}
              </div>
              <div>
                <NormalBtn
                  isLoading={isUploadingCardapioImage}
                  disabled={!image && true}
                  value={image ? "Enviar Cardápio" : "Carregar Imagem"}
                  onClick={image ? handleSave : handleUploadLocal}
                />
                {!!image && !isUploadingCardapioImage && (
                  <NormalBtn
                    deleteBtn
                    isLoading={false}
                    disabled={!image && true}
                    value={"Remover Imagem"}
                    onClick={handleDeleteImage}
                  />
                )}
              </div>
            </div>
          </>
        }
      >
        <MenuForm image={image} handleImageChange={handleImageChange} />
      </MainCard>
    </Container>
  );
};

export default Cardapio;
