import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import phone from "../../../../../assets/images/brasil.png";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useUpdateAdmin from "../../../api/Admin/useUpdateAdmin";

const EditAdminForm = ({ admin }) => {
  const [nome, setNome] = useState(admin.nome);
  const [email, setEmail] = useState(admin.email);
  const [telefone, setTelefone] = useState(admin.telefone.replace("+55", ""));
  const { mutate: updateAdmin, isLoading } = useUpdateAdmin();

  const handleSave = (close) => {
    const data = {
      nome: nome,
      email: email,
      telefone: "+55" + telefone,
      password: admin.passw,
    };

    if (!nome || !email || !telefone) {
      toast.error("Preencha todos os campos🙏", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!regexEmail.test(email)) {
      toast.error("E-mail inválido 📧", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    updateAdmin(
      {
        body: data,
        id: admin.id,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      editBtn
      submit={handleSave}
      buttonValue="Editar"
      isLoading={isLoading}
    >
      <>
        {" "}
        <div className=" w-100">
          <div className="form-group my-3 w-100">
            <label htmlFor="nome">Nome:</label>
            <input
              id="nome"
              type="text"
              className="form-control"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group my-3 w-100">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
          </div>
          <div className="form-group my-3 w-100">
            <label htmlFor="telefone">Telefone:</label>
            <div className="phone-input rounded p-2">
              <span className="d-flex align-items-center">
                <img src={phone} alt="" />
                <span> +55</span>
              </span>
              <input
                type="text"
                id="telefone"
                maxLength={10}
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </div>
          </div>
        </div>
      </>
    </ModalForm>
  );
};

export default EditAdminForm;
