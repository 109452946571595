import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchMoreOption = async (idPlano) => {
  const res = await ManjarApi.get(`/plano-assinado/more-options/${idPlano}`);
  return res?.data;
};

const useGetMoreOptions = ({ idPlano }) => {
  const { data: options, isLoading: isGettingOptions } = useQuery({
    queryKey: ["more-options", idPlano],
    queryFn: () => fetchMoreOption(idPlano),
  });
  return { options, isGettingOptions };
};

export default useGetMoreOptions;
