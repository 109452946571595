import "./styles.scss";
// import Pusher from "pusher-js";
import React, { useState, useEffect } from "react";
import MessageChat from "./components/MessageChat";
import Container from "../../components/Layout/Container";
import ClientesSidebarChat from "./components/ClientesSidebarChat";
import { ChooseClienteContextProvider } from "./context/useChooseCliente";

const Chat = () => {
  const [messages, setMessages] = useState([]);

  // useEffect(() => {
  //   var pusher = new Pusher("5193c935ffe38d24d56b", {
  //     cluster: "ap2",
  //   });

  //   var channel = pusher.subscribe("my-channel");
  //   channel.bind("my-event", function (data) {
  //     alert(JSON.stringify(data));
  //   });
  // }, [messages]);

  // componentDidMount() {
  //   const username = window.prompt('Username: ', 'Anonymous');
  //   this.setState({ username });
  //   const pusher = new Pusher('APP_KEY', {
  //     cluster: 'APP_CLUSTER',
  //     encrypted: true
  //   });
  //   const channel = pusher.subscribe('chat');
  //   channel.bind('message', data => {
  //     this.setState({ chats: [...this.state.chats, data], test: '' });
  //   });
  //   this.handleTextChange = this.handleTextChange.bind(this);
  // }

  // handleTextChange(e) {
  //   if (e.keyCode === 13) {
  //     const payload = {
  //       username: this.state.username,
  //       message: this.state.text
  //     };
  //     axios.post('http://localhost:5000/message', payload);
  //   } else {
  //     this.setState({ text: e.target.value });
  //   }
  // }

  return (
    <ChooseClienteContextProvider>
      <Container title={"Mensagens de clientes"}>
        <div className="chat">
          <div className="container-chat">
            <ClientesSidebarChat />
            <MessageChat />
          </div>
        </div>
      </Container>
    </ChooseClienteContextProvider>
  );
};

export default Chat;
