import React, { useState } from "react";
import { Send } from "@mui/icons-material";
import { useChooseCliente } from "../context/useChooseCliente";
import useSendMessage from "../api/useSendMessage";

const SendMessageInput = ({ onClick }) => {
  const [message, setMessage] = useState("");
  const { clienteId } = useChooseCliente();
  const { sendMessage, isSendingMessage } = useSendMessage();

  const handleSend = async () => {
    if (message === "") {
      return;
    }

    const data = {
      message: message,
      id_cliente: clienteId,
      sender: "admin",
    };

    sendMessage(
      { ...data },
      {
        onSuccess() {
          setMessage("");
        },
      }
    );
  };

  return (
    <div className="send-input-message" onClick={onClick}>
      <div className="card-app send-input-message-container">
        <textarea
          type="text"
          value={message}
          placeholder="Escrever uma mensagem"
          onChange={(e) => setMessage(e.target.value)}
        />
        <button disabled={isSendingMessage} onClick={handleSend}>
          <Send
            style={{ color: isSendingMessage ? "ligthgray" : "var(--primary)" }}
          />
        </button>
      </div>
    </div>
  );
};

export default SendMessageInput;
