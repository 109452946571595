import { Cidades } from "./components/CidadesList";
import Container from "../../components/Layout/Container";
import CardRightCidades from "./components/CardRightCidades";
import { useViewBairros } from "./context/ViewBairrosContext";
import MainCard from "../../components/Feature/Cards/MainCard";
import AddCities from "./components/forms/cidade/AddCities";

const Cities = () => {
  const { showCard, setShowCard } = useViewBairros();

  return (
    <Container
      title={"Cidades"}
      buttons={<AddCities />}
      showCard={showCard}
      descCardContent={<CardRightCidades />}
      setShowCard={() => setShowCard(showCard ? false : true)}
    >
      <MainCard>
        <Cidades />
      </MainCard>
    </Container>
  );
};

export default Cities;
