import React from "react";
import "../production.scss";
import Navbar from "./Navbar";
import DescCard from "../../../components/Feature/Cards/DescCard";

const ContainerProdution = ({
  title,
  children,
  buttons,
  showCard,
  setShowCard,
  descCardContent,
  setAbaChange,
  abaChange,
  setTitle,
}) => {
  return (
    <div className="container-app-production  w-100 d-flex">
      <Navbar
        abaChange={abaChange}
        setAbaChange={setAbaChange}
        setTitle={setTitle}
      />
      <div
        className={`container-content-app-production  ${
          showCard && "active-app"
        }`}
      >
        <div className={`container-view-production`}>
          <div className="container-content-app-container">
            <div className="header-content-view">
              <h3 className="py-2">{title}</h3>
              {buttons}
            </div>
            {children}
          </div>
        </div>
        {showCard && (
          <DescCard setShow={setShowCard} className={""}>
            {descCardContent}
          </DescCard>
        )}
      </div>
    </div>
  );
};

export default ContainerProdution;
