import CardUser from "./CardUser";
import React from "react";
import SearchUserChat from "./SearchUserChat";
import Loading from "../../../components/Common/Loading";
import useGetChatClientes from "../api/useGetChatClientes";
import { useChooseCliente } from "../context/useChooseCliente";

const ClientesSidebarChat = () => {
  const { clienteId, setClienteChatId } = useChooseCliente();
  const { chatClientes, isGettingChatClientes } = useGetChatClientes();

  const getChatByUserId = (id) => {
    setClienteChatId(id);
  };

  return (
    <div className="card-app users-sidebar-chat">
      <SearchUserChat />
      <div
        className={`users-sidebar-chat-container ${
          isGettingChatClientes && "loading"
        }`}
      >
        {isGettingChatClientes ? (
          <Loading />
        ) : (
          chatClientes?.map((cliente) => (
            <CardUser
              data={cliente}
              onClick={() => getChatByUserId(cliente.id)}
              active={clienteId}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ClientesSidebarChat;
