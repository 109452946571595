import "./styles.scss";
import Logo from "../../Common/Logo";
import Navigation from "./Navigation";
import Avatar from "../../Common/Avatar";
import { Email } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className={`navBar`}>
      <div className="navBar-container">
        <Logo />
        <Navigation />
        <div className="d-flex align-items-center justify-content-center">
          <Link to={"/chat"} className="mx-4">
            <Email style={{ fontSize: "25px", color: "var(--primary)" }} />
          </Link>
          <Avatar />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
