import { toast } from "react-toastify";
import React, { useState } from "react";
import { Delete } from "@mui/icons-material";
import useGetBairros from "../api/bairro/useGetBairros";
import { useViewBairros } from "../context/ViewBairrosContext";
import CardRight from "../../../components/Layout/Container/CardRight";
import Table from "../../../components/Feature/Table";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import AddNeighborhood from "./forms/bairro/AddNeighborhood";
import EditNeighborhood from "./forms/bairro/EditNeighborhood";
import NormalBtn from "../../../components/Common/Buttons/NormalBtn";
import SmallLoading from "../../../components/Common/SmallLoading";
import useDeleteBairro from "../api/bairro/useDeleteBairro";

const CardRightCidades = () => {
  const { idCidade } = useViewBairros();
  const [idBairro, setIdBairro] = useState();
  const { data, isGetting } = useGetBairros({ idCidade });
  const { mutate: deleteBairro, isLoading } = useDeleteBairro();

  const handleDelete = (id) => {
    setIdBairro(id);
    deleteBairro(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <CardRight isLoading={isGetting} itemsAtList={data?.bairros.length}>
      <div className="display-flex-between-row">
        <h5
          className="user-name  align-self-center"
          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          {data?.cidade.nome}
        </h5>
        <AddNeighborhood />
      </div>
      <div className="lineSolid" />
      <div className="scroll-card-right">
        <Table
          notFoundSmall
          descriptionItemList={"Lista vazia"}
          itemsAtList={data?.bairros ? data?.bairros.length : 0}
        >
          {data?.bairros.map((item) => (
            <ItemsRow type={"items-row"}>
              <td style={{ textAlign: "left" }}>{item.nome}</td>
              <td>
                <EditNeighborhood bairro={item} />
              </td>
              <td>
                <div className="display-items-center"></div>
                <NormalBtn
                  deleteBtn
                  delectDesc={"Bairro"}
                  iconBtn={
                    <span className="icon-btn  delete-icon-btn">
                      {item.id === idBairro && isLoading ? (
                        <SmallLoading />
                      ) : (
                        <Delete className="icon-btn-icon" />
                      )}
                    </span>
                  }
                  onClick={() => handleDelete(item.id)}
                />
              </td>
            </ItemsRow>
          ))}
        </Table>
      </div>
    </CardRight>
  );
};

export default CardRightCidades;
