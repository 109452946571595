import "./styles.scss";
import { useState } from "react";
import "../../styles/Global.scss";
import Logo from "../../components/Common/Logo";
import { Link } from "react-router-dom";
import NormalBtn from "../../components/Common/Buttons/NormalBtn";
import { useLoginApi } from "./api/useLoginApi";

const Login = () => {
  const { login } = useLoginApi();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleLogin = (e) => {
    login({ email, password });
  };

  return (
    <div className="login d-flex align-items-center vh-100 backgroundeImage">
      <div className="login-form container border border-gray p-4 rounded bg-white d-flex flex-column align-items-center">
        <Logo width="163" height="140" />
        <div className="w-100 mb-3 mt-4">
          <input
            type="email"
            id="exampleInputEmail1"
            className="w-100 form-control py-3"
            aria-describedby="emailHelp"
            placeholder="Email ou código de usuário"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="w-100 mb-4">
          <input
            type="password"
            className="w-100 form-control py-3"
            id="exampleInputPassword1"
            placeholder="Senha"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <NormalBtn
          value={"Entrar"}
          className={"w-100 button-form mb-4"}
          onClick={(e) => handleLogin(e)}
        />
        <div className="align-self-end">
          <Link to="#">Esqueci minha senha</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
