import React from "react";
import { Circle } from "@mui/icons-material";
import Table from "../../../components/Feature/Table";
import useGetPeferencias from "../api/useGetPreferencias";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import { useViewProductionContext } from "../context/ViewProductionContext";
import CardRight from "../../../components/Layout/Container/CardRight";
import { refatorarText } from "../../../utils/stringUtils";

const CardRightPreferrencias = () => {
  const { idCliente } = useViewProductionContext();
  const { preferencias, isGettingPreferencias } = useGetPeferencias({
    idCliente,
  });

  return (
    <CardRight isLoading={isGettingPreferencias}>
      <h5
        className="user-name  align-self-center"
        style={{ fontSize: "1.3rem", fontWeight: "bold" }}
      >
        {refatorarText(preferencias?.cliente ?? "", 20)}
      </h5>
      <div className="lineSolid" />
      <div className="scroll-card-right">
        <Table
          notFoundSmall
          descriptionItemList={"Lista vazia"}
          itemsAtList={
            preferencias?.rejeicoes_gastronomicas
              ? preferencias?.rejeicoes_gastronomicas?.length
              : 0
          }
        >
          {preferencias?.rejeicoes_gastronomicas.map((item) => (
            <ItemsRow type={"items-row"}>
              <td>
                <Circle
                  style={{ color: "red", height: "1rem", width: "1rem" }}
                />
              </td>
              <td style={{ fontWeight: "bold" }}>{item.descricao}</td>
            </ItemsRow>
          ))}
        </Table>
      </div>
    </CardRight>
  );
};

export default CardRightPreferrencias;
