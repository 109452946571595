import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";
import { Edit } from "@mui/icons-material";
import useUpdateCategoria from "../../../api/categoria/useUpdateCategoria";

const EditCategory = ({ categoria }) => {
  const [nome, setNome] = useState(categoria.nome);
  const [desc, setDesc] = useState(categoria.description);
  const { mutate: updatecategoria, isLoading } = useUpdateCategoria();

  const handleSave = (close) => {
    if (!nome || !desc) {
      toast.error("Preencha todos os campos.");
      return;
    }

    updatecategoria(
      {
        body: { nome: getFullNameWithInitials(nome), description: desc },
        id: categoria.id,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      iconBtn={
        <span className="icon-btn  edit-icon-btn">
          <Edit className="icon-btn-icon" />
        </span>
      }
      submit={handleSave}
      buttonValue="Editar"
      isLoading={isLoading}
    >
      <>
        <div>
          <div className="form-group my-3">
            <label htmlFor="nome">Nome:</label>
            <input
              id="nome"
              type="text"
              name="nome"
              value={nome}
              className="form-control"
              placeholder="Insira o nome da categoria"
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="desc">Descrição:</label>
            <textarea
              id="desc"
              name="desc"
              value={desc}
              className="form-control"
              style={{ height: "300px" }}
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Adiciona uma descrição para categoria"
            />
          </div>
        </div>
      </>
    </ModalForm>
  );
};

export default EditCategory;
