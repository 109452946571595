import { toast } from "react-toastify";
import NormalBtn from "../../../../components/Common/Buttons/NormalBtn";
import Table from "../../../../components/Feature/Table";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";
import useGetEntregadores from "../../api/Entregador/useGetEntregadores";
import { fields_entregadores } from "../../fields";
import EditEntregadorForm from "../Forms/Entregadores/EditEntregadorForm";
import RotaEntregador from "./RotaEntregador";
import { useState } from "react";
import useDeleteEntregador from "../../api/Entregador/useDeleteEntregador";

const Entregadores = () => {
  const [idEntregador, setIdEntregador] = useState();
  const { entregadores, isGettingEntregadores } = useGetEntregadores();
  const { mutate: deleteEntregador, isLoading: isDeletingEntregador } =
    useDeleteEntregador();

  const handleDelete = (id) => {
    setIdEntregador(id);
    deleteEntregador(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <Table
      isLoading={isGettingEntregadores}
      itemsAtList={entregadores ? entregadores?.length : 0}
      descriptionItemList={"Não há entregadores registradas"}
    >
      <ItemsRow type={"items-row-header"}>
        {fields_entregadores.map((field) => (
          <th
            className={
              field.value === "estado_entregador" && "estado_entregador"
            }
          >
            {field.value !== "code" && field.label}
          </th>
        ))}
      </ItemsRow>
      {entregadores?.map((item) => (
        <ItemsRow type={"items-row"} borderColor={"red"}>
          <td className="fw-bold">{item?.id}</td>
          <td className="item-cell status-entregador">
            <div
              className={`container-status-entregador ${
                item.estado ? "status-online" : "status-offline"
              } rounded fw-bold`}
            >
              <span className="status-circle" />
              {item.estado ? "Online" : "Offline"}
            </div>
          </td>
          <td className="item-cell fw-bold">{item?.name}</td>
          <td className="item-cell">
            <div>
              <RotaEntregador id={item?.id} />
            </div>
          </td>
          <td>
            <div>
              <EditEntregadorForm entregador={item} />
            </div>
          </td>
          <td className="item-cell">
            <div className="display-item-center">
              <NormalBtn
                deleteBtn
                onClick={() => handleDelete(item.id)}
                isLoading={item.id === idEntregador && isDeletingEntregador}
                delectDesc={"entregar"}
                value={"Eliminar"}
              />
            </div>
          </td>
        </ItemsRow>
      ))}
    </Table>
  );
};

export default Entregadores;
