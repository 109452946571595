export const fields_products = [
  { value: "code", label: "Código" },
  { value: "img", label: "Imagem" },
  { value: "nome", label: "Nome" },
  { value: "categoria", label: "Categória" },
  { value: "estoque", label: "Estoque" },
  { value: "preco", label: "Preço" },
  { value: "btn", label: "Ver" },
  { value: "btn", label: "Eliminar" },
];
