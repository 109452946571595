import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const queryFn = async (id) => {
  const res = await ManjarApi.get(`/rotas/entregador/${id}`);
  return res?.data;
};

const useGetEntregadorRotas = ({ id }) => {
  const { data: rotasEntregador, isLoading: isGettingRotasEntregador } =
    useQuery({
      queryKey: ["rotas-entregador", id],
      queryFn: () => queryFn(id),
    });

  return { rotasEntregador, isGettingRotasEntregador };
};

export default useGetEntregadorRotas;
