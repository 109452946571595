import React, { useState } from "react";
import { toast } from "react-toastify";
import Table from "../../../components/Feature/Table";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import { fields_cidade } from "../fields";
import { useViewBairros } from "../context/ViewBairrosContext";
import useGetCidades from "../api/cidade/useGetCidades";
import NormalBtn from "../../../components/Common/Buttons/NormalBtn";
import EditCidade from "./forms/cidade/EditCities";
import useDeleteCidade from "../api/cidade/useDeleteCidate";

export const Cidades = () => {
  const [idIsPrCidade, setIdIsPrCidade] = useState();
  const { setIdCidade, setShowCard } = useViewBairros();
  const { cidades, isGettingCidades } = useGetCidades();
  const { mutate: deleteCidade, isLoading } = useDeleteCidade();

  const handleDelete = (id) => {
    setIdIsPrCidade(id);
    deleteCidade(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <Table
      isLoading={isGettingCidades}
      itemsAtList={cidades ? cidades?.length : 0}
      descriptionItemList={"Não há cidades registradas"}
    >
      <ItemsRow type={"items-row-header"}>
        {fields_cidade.map((field) => (
          <th>{field.value !== "code" && field.label}</th>
        ))}
      </ItemsRow>
      {cidades?.map((item) => (
        <ItemsRow type={"items-row"}>
          <td className="item-code fw-bold">{item.id}</td>
          <td className="fw-bold">{item.nome}</td>
          <td className="">{item.bairros_count}</td>
          <td className="">
            <NormalBtn
              value={"Ver Bairros"}
              onClick={() => {
                setIdCidade(item.id);
                setShowCard(true);
              }}
            />
          </td>
          <td>
            <EditCidade cidade={item} />
          </td>
          <td>
            <div
              style={{
                display: "flex",
                dignItems: "center",
                justifyContent: "center",
              }}
            >
              <NormalBtn
                deleteBtn
                isLoading={idIsPrCidade === item.id && isLoading}
                onClick={() => handleDelete(item.id)}
                value={"Eliminar"}
                delectDesc={"Cidade"}
              />
            </div>
          </td>
        </ItemsRow>
      ))}
    </Table>
  );
};
