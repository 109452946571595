import "./styles.scss";
import React from "react";

const ColorPicker = ({ selectedColor, setSelectedColor }) => {
  const cores = [
    "#2C3E50", // Azul escuro
    "#34495E", // Cinza escuro
    "#16A085", // Verde Esmeralda
    "#F39C12", // Amarelo
    "#E74C3C", // Vermelho
    "#FF5733", // Laranja
    "#8E44AD", // Roxo
    "#D35400", // Laranja Escuro
    "#F7DC6F", // Amarelo Claro
    "#5D6D7E", // Cinza Azulado
    "#EC7063", // Vermelho Claro
    "#BDC3C7", // Prata
    "#1ABC9C", // Turquesa
    "#FAD02E", // Amarelo Brilhante
    "#FFC300", // Amarelo Forte
    "#2ECC71", // Verde Forte
  ];

  const cores_padrao = ["#6aad68", "#a86bd7", "#f44646"];

  const handleColorClick = (cor) => {
    setSelectedColor(cor);
  };

  return (
    <div className="container color-picker">
      <div className="color-picker">
        <label htmlFor="price" className="mb-3">
          Escolha uma cor{" "}
        </label>

        <div>
          <label htmlFor="price">Cores recomendadas: </label>
          <div className="row mb-2 p-4">
            {cores_padrao.map((cor, index) => (
              <di
                key={index}
                className="col-2 square-list"
                onClick={() => handleColorClick(cor)}
                style={{
                  backgroundColor: cor,
                  border: selectedColor === cor ? "2px solid black" : "none",
                }}
              />
            ))}
          </div>
        </div>
        <div>
          <label htmlFor="price">Cores adicionais: </label>
          <div className="row p-4">
            {cores.map((cor, index) => (
              <div
                key={index}
                className="col-2 square-list"
                onClick={() => handleColorClick(cor)}
                style={{
                  backgroundColor: cor,
                  border: selectedColor === cor ? "2px solid black" : "none",
                }}
              />
            ))}
          </div>
        </div>
      </div>

      {!!selectedColor && (
        <label className="">
          Cor selecionada:
          <div
            style={{
              backgroundColor: selectedColor,
            }}
            className="square"
          />
        </label>
      )}
    </div>
  );
};

export default ColorPicker;
