import "./home.scss";
import "../../styles/Global.scss";
import Chart from "./components/Chart/Chart";
import Widget from "./components/widget/Widget";
import useGetHomeData from "./api/useGetHomeData";
import Loading from "../../components/Common/Loading";
import Featured from "./components/Featured/Featured";
import Container from "../../components/Layout/Container";
import MainCard from "../../components/Feature/Cards/MainCard";
import SearchNotFounded from "../../components/Common/SeachNotFounded";
import RecentOrders from "./components/RecentOreders";

const Home = () => {
  const { home, isGettingHome } = useGetHomeData();

  return (
    <Container title={"Início"}>
      {isGettingHome ? (
        <div className="home-loading">
          <div className="isLoading">
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <div className="widgets flex">
            <Widget
              type="user"
              value={!isGettingHome && home?.total_clientes}
            />
            <Widget type="deliver" />
            <Widget type="earning" />
            <Widget type="earning total" />
            <Widget type="balance" />
          </div>
          <div className="charts">
            <Featured />
            <Chart title="Últimos meses" aspect={4 / 1} />
          </div>
          <MainCard cardLabel={"Pedidos recentes"} notShowInputSearch>
            <RecentOrders />
          </MainCard>
        </>
      )}
    </Container>
  );
};

export default Home;
