import React from "react";
import "./styles.scss";
import search from "../../../assets/images/search.png";

const SearchNotFounded = ({ description, small }) => {
  return (
    <div className="search-not-founded">
      <img
        src={search}
        alt=""
        srcset=""
        style={{
          width: small ? "7rem" : "15rem",
          height: small ? "7rem" : "15rem",
          marginBottom: small ? "30px" : "4rem",
        }}
      />
      <h3>{description}</h3>
    </div>
  );
};

export default SearchNotFounded;
