import "../styles.scss";
import React, { useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import Table from "../../../../components/Feature/Table";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";
import useGetEntregadorRotas from "../../api/Entregador/useGetEntregadorRotas";
import NormalBtn from "../../../../components/Common/Buttons/NormalBtn";
import Modal from "../../../../components/Common/Modal";

const RotaEntregador = ({ id }) => {
  const [showModal, setShowModal] = useState(false);
  const { rotasEntregador, isGettingRotasEntregador } = useGetEntregadorRotas({
    id,
  });

  return (
    <div className="display-item-center">
      <NormalBtn value={"Ver Rotas"} onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal>
          <div className="container w-75 h-50 bg-white py-5 rounded position-relative align-self-center">
            <button className="close-btn" onClick={() => setShowModal(false)}>
              <CloseOutlined
                style={{
                  height: "20px",
                  width: "20px",
                  color: "white",
                  borderRadius: "10px",
                }}
              />
            </button>
            <h5 className="lineDashed text-start fw-bold pb-2">
              Rotas {rotasEntregador?.entregador}
            </h5>
            <div className="lineSolid" />
            <div className="mb-4" />
            <div className="bairros-Scroll">
              <Table
                notFoundSmall
                className="bairros-Scroll"
                isLoading={isGettingRotasEntregador}
                itemsAtList={
                  rotasEntregador ? rotasEntregador?.enderecos?.length : 0
                }
                descriptionItemList={"Não há entregadores registradas"}
              >
                <ItemsRow type={"items-row-header"}>
                  <th></th>
                  <th>Cliente</th>
                  <th>Bairro</th>
                  <th>Cidade</th>
                  <th>Endereço</th>
                </ItemsRow>
                {rotasEntregador?.enderecos?.map((item, index) => (
                  <ItemsRow type={"items-row"}>
                    <td className="code">{index + 1}</td>
                    <td className="py-3 fw-semibold">{item?.cliente}</td>
                    <td className="py-3">{item.bairro}</td>
                    <td className="py-3">{item?.cidade}</td>
                    <td className="py-3">{item?.endereco ?? "endereco"}</td>
                  </ItemsRow>
                ))}
              </Table>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RotaEntregador;
