import React from "react";
import { Tooltip } from "react-tooltip";
import { fields_production } from "../fields";
import { CheckBox, Close } from "@mui/icons-material";
import Table from "../../../components/Feature/Table";
import {
  refactorPlanoClassName,
  refactorPlanoClassNameBorder,
  removePlano,
} from "../../../utils/planoUtils";
import { refatorarText } from "../../../utils/stringUtils";
import useGetPlanosAssinados from "../api/useGetPlanosAssinados";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import { useViewProductionContext } from "../context/ViewProductionContext";

const ProductionList = () => {
  const { planos_assinados, isGettingPlanosAssinados } =
    useGetPlanosAssinados();
  const { setIdCliente, setIdPlano, setShowCard } = useViewProductionContext();

  return (
    <Table
      isLoading={isGettingPlanosAssinados}
      descriptionItemList={"Não há pedidos"}
      itemsAtList={planos_assinados ? planos_assinados?.length : 0}
    >
      <thead>
        <ItemsRow type={"items-row-header"}>
          {fields_production.map((field) => (
            <th className={"item-cell fw-bold"}>
              {field.value !== "code" && field.label}
            </th>
          ))}
        </ItemsRow>
      </thead>
      <tbody>
        {planos_assinados?.map((item) => (
          <ItemsRow type={"items-row"}>
            <td
              className={`item-code ${refactorPlanoClassNameBorder(
                item.plano
              )}`}
            >
              {item.id}
            </td>
            <td className="fw-bold">{item.cliente}</td>
            <td>
              <span
                className={`p-2 rounded ${refactorPlanoClassName(item.plano)}`}
              >
                {item?.plano ? removePlano(item?.plano) : "Avulso"}
              </span>
            </td>
            <td id={`endereco${item.id}`}>
              <Tooltip
                anchorSelect={`#endereco${item.id}`}
                content={item.endereco}
              />
              {refatorarText(item.endereco, 15)}
            </td>
            <td>{item.entregador}</td>
            <td>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setIdCliente(item.id_cliente);
                  setShowCard(1);
                }}
              >
                Preferências
              </button>
            </td>
            <td>
              <button
                type="button"
                className="btn btn-success py-1 px-2"
                onClick={() => {
                  setIdPlano(item.id);
                  setShowCard(2);
                }}
              >
                Detalhes
              </button>
            </td>
            <td className="item-cell px-2">
              <DayReceiveOrNot day={item.seg} />
            </td>
            <td className="item-cell px-2">
              <DayReceiveOrNot day={item.ter} />
            </td>
            <td className="item-cell px-2">
              <DayReceiveOrNot day={item.qua} />
            </td>
            <td className="item-cell px-2">
              <DayReceiveOrNot day={item.qui} />
            </td>
            <td className="item-cell px-2">
              <DayReceiveOrNot day={item.sex} />
            </td>
          </ItemsRow>
        ))}
      </tbody>
    </Table>
  );
};

const DayReceiveOrNot = ({ day }) => {
  if (day === 1) {
    return (
      <CheckBox style={{ color: "green", height: "2rem", width: "2rem" }} />
    );
  }
  return (
    <Close
      style={{
        color: "white",
        width: "1.5rem",
        height: "1.5rem",
        background: "red",
        borderRadius: "3px",
      }}
    />
  );
};

export default ProductionList;
