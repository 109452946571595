import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.patch(`/produtos/${data.id}`, {
    ...data.body,
  });
  return res.data?.produto;
};

const useUpdateProduto = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      queryClient.setQueryData(["produtos"], (prev_data) => {
        return prev_data?.map((old) => {
          if (old.id === data.id) {
            return data;
          }
          return old;
        });
      });
      queryClient.setQueryData(["produto", data.id], { ...data });
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["produtos"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["produtos"] });
    },
  });
};

export default useUpdateProduto;
