import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const mutateFn = async (data) => {
  console.table(data);
  const res = await ManjarApi.post("/planos", data);
  return res.data?.plano;
};

const useCreatePlano = () => {
  const queryClient = useQueryClient();
  const { mutate: createPlano, isLoading: isPosttingPlano } = useMutation({
    mutationFn: (data) => mutateFn(data),
    onSuccess: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ["planos"],
      });
      queryClient.setQueryData(["planos"], (old) => [...old, data]);
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["planos"], context.prevData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["planos"] });
    },
  });
  return { createPlano, isPosttingPlano };
};

export default useCreatePlano;
