import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchBairros = async (data) => {
  const res = await ManjarApi.post("/bairros", data);
  console.log(res);
  return res;
};

const useCreateBairro = ({ idCidade }) => {
  const queryClient = useQueryClient();
  const { mutate: createBairro, isLoading: isPosttingBairro } = useMutation({
    mutationFn: (data) => fetchBairros(data),
    onSuccess: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ["bairros", idCidade],
      });

      queryClient.setQueryData(["bairros", idCidade], (old) => {
        return { ...old, bairros: [...old.bairros, data] };
      });
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["bairros", idCidade], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["bairros", idCidade] });
    },
  });
  return { createBairro, isPosttingBairro };
};

export default useCreateBairro;
