export const fields_entregas = [
  { value: "", label: "" },
  { value: "id", label: "Cod. Pedido" },
  { value: "cliente", label: "Cliente" },
  { value: "endereco", label: "Endereço" },
  { value: "entregador", label: "Entregador" },
  { value: "hora_entrega", label: "Hora de entrega" },
  { value: "hora_saida", label: "Hora saída" },
  { value: "status_entrega", label: "Estatus" },
];

export const fields_rota = [
  { value: "code", label: "Código" },
  { value: "cliente", label: "Cliente" },
  { value: "entregador", label: "Entregador" },
  { value: "bairro", label: "Bairro" },
  { value: "cidade", label: "Cidade" },
  { value: "endereco", label: "Endereco" },
];
