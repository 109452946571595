import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (id) => {
  const res = await ManjarApi.put(`/cliente-block/${id}`);
  return res?.data;
};

const useChamgeEstadoCliente = () => {
  const queryClient = useQueryClient();
  const { mutate: changeEstadoCliente, isLoading: isChangingEstadoCliente } =
    useMutation({
      mutationFn: (data) => mutationFn(data.id),
      onSuccess: ({ estado, id_cliente }) => {
        const prev_data = queryClient.getQueryData(["clientes"]);
        queryClient.setQueryData(
          ["clientes"],
          prev_data.map((old) => {
            if (old.id === id_cliente) {
              return {
                ...old,
                estado,
              };
            }
            return old;
          })
        );
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(["clientes"], context.prev_data);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["clientes"] });
      },
      retry: 2,
    });
  return {
    changeEstadoCliente,
    isChangingEstadoCliente,
  };
};

export default useChamgeEstadoCliente;
