import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Table from "../../../../components/Feature/Table";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";
import { fields_address } from "../../fields";
import { refatorarText } from "../../../../utils/stringUtils";
import EditAddressForm from "../Forms/Endereco/EditAddressForm";
import NormalBtn from "../../../../components/Common/Buttons/NormalBtn";
import useDeleteEndereco from "../../api/clientes/useDeleteEndereco";
import { useState } from "react";

const Enderecos = ({ enderecos, isGettingEnderecos }) => {
  const [idEndereco, setIdEndereco] = useState();
  const { mutate: deleteEndereco, isLoading: isDeletingEndereco } =
    useDeleteEndereco();

  const handleDelete = (id) => {
    setIdEndereco(id);
    deleteEndereco(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <div className="position-relative">
      <Table
        isLoading={isGettingEnderecos}
        itemsAtList={enderecos ? enderecos?.length : 0}
        descriptionItemList={"Não há endereços registradas"}
      >
        <ItemsRow type={"items-row-header"}>
          {fields_address.map((field) => (
            <th
              className={
                field.value === "code" ? "item-cell item-code" : "item-cell"
              }
            >
              {field.value !== "code" && field.label}
            </th>
          ))}
        </ItemsRow>
        {enderecos?.map((item) => (
          <ItemsRow type={"items-row"}>
            <td className="item-cell item-code fw-bold">{item.id}</td>
            <td className="item-cell">{item.cep}</td>
            <td className="item-cell">{item.entregador}</td>
            <td className="item-cell">{item.cidade}</td>
            <td className="item-cell">{item.bairro}</td>
            <td className="item-cell" id={`endereco${item.id}`}>
              <Tooltip
                anchorSelect={`#endereco${item.id}`}
                content={item.endereco}
              />
              {refatorarText(item.endereco, 10)}
            </td>
            <td className="item-cell">{item.numero}</td>
            <td className="item-cell" id={`complemento${item.id}`}>
              <Tooltip
                anchorSelect={`#complemento${item.id}`}
                content={item.complemento}
              />
              {refatorarText(item.complemento ?? "", 10)}
            </td>
            <td>
              <EditAddressForm endereco={item} />
            </td>
            <td className="item-cell py-2">
              <NormalBtn
                deleteBtn
                value={"Eliminar"}
                delectDesc={"endereço"}
                isLoading={item.id === idEndereco && isDeletingEndereco}
                onClick={() => handleDelete(item.id)}
              />
            </td>
          </ItemsRow>
        ))}
      </Table>
    </div>
  );
};

export default Enderecos;
