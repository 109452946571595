import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCategoria = async (data) => {
  const res = await ManjarApi.post("/produtos", data);
  return res;
};

const useCreateProdutos = () => {
  const queryClient = useQueryClient();
  const { mutate: createProduto, isLoading: isPosttingProduto } = useMutation({
    mutationFn: (data) => fetchCategoria(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ["produtos"],
      });
      const prev_data = queryClient.getQueryData(["produtos"]);
      queryClient.setQueryData(["produtos"], (old) => [...old, data]);

      return { prev_data };
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["produtos"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["produtos"] });
    },
  });
  return { createProduto, isPosttingProduto };
};

export default useCreateProdutos;
