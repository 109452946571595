import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.patch(`/bairros/${data.id}`, { ...data.body });
  return res.data.bairro;
};

const useUpdateBairro = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      queryClient.setQueryData(["bairros", data.idCidade], (prev_data) => {
        return {
          cidade: prev_data.cidade,
          bairros: prev_data?.bairros.map((old) => {
            if (old.id === data.id) {
              return data;
            }
            return old;
          }),
        };
      });
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["bairros", data.idCidade], context.prev_data);
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: ["bairros", data.idCidade] });
    },
  });
};

export default useUpdateBairro;
