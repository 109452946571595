import "./styles.scss";
import Modal from "../../Common/Modal";
import React, { useState } from "react";
import TypesRotasConfig from "./TypesRotasConfig";
import NormalBtn from "../../Common/Buttons/NormalBtn";

export const ConfigRotas = () => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <>
      <NormalBtn
        value={"Configurar Rotas"}
        onClick={() => setShowOptions(true)}
      />
      {showOptions && (
        <Modal>
          <TypesRotasConfig setShow={setShowOptions} />
        </Modal>
      )}
    </>
  );
};

export default ConfigRotas;
