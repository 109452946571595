import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useGetCategorias from "../../../api/categoria/useGetCategorias";
import SmallLoading from "../../../../../components/Common/SmallLoading";
import useCreateProdutos from "../../../api/produto/useCreateProdutos";

const AddProduct = () => {
  const [nome, setNome] = useState("");
  const [categoria, setCategoria] = useState("");
  const [estoque, setEstoque] = useState(0);
  const [preco, setPreco] = useState(0);
  const [desc, setDesc] = useState("");
  const { categorias, isGettingCategorias } = useGetCategorias();
  const { createProduto, isPosttingProduto } = useCreateProdutos();

  const handleSubmit = (close) => {
    if (!nome || !categoria || !desc || !categorias) {
      toast.error("Preencha todos os campos.");
      return;
    }
    createProduto(
      {
        description: desc,
        preco: Number(preco),
        stock: Number(estoque),
        nome: getFullNameWithInitials(nome),
        idCategoria: Number(categoria),
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      submit={handleSubmit}
      buttonValue="Adicionar Produto"
      isLoading={isPosttingProduto}
    >
      <>
        <div className="mb-3">
          <div className="mb-3">
            <label htmlFor="nome" className="form-label">
              Nome:
            </label>
            <input
              id="nome"
              type="text"
              value={nome}
              className="form-control"
              placeholder="Ex. Salada"
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <label htmlFor="categoria" className="form-label">
            Categoria:
          </label>
          <select
            id="categoria"
            className="form-select"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          >
            <option value="">
              {isGettingCategorias ? <SmallLoading /> : "Selecione a categoria"}
            </option>
            {categorias?.map((item) => (
              <option value={item.id}>{item.nome}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="estoque" className="form-label">
            Estoque:
          </label>
          <input
            type="number"
            id="estoque"
            className="form-control"
            value={estoque}
            onChange={(e) => setEstoque(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="preco" className="form-label">
            Preço:
          </label>
          <input
            type="number"
            id="preco"
            className="form-control"
            value={preco}
            onChange={(e) => setPreco(e.target.value)}
          />
        </div>
        <div className="form-group my-3">
          <label htmlFor="desc">Descrição:</label>
          <textarea
            id="desc"
            name="desc"
            value={desc}
            className="form-control"
            placeholder="Adiciona uma descrição para o produto"
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </>
    </ModalForm>
  );
};

export default AddProduct;
