import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const queryFn = async () => {
  const res = await ManjarApi.get("/cardapios");
  return res.data?.cardapios.sort((a, b) => b.id - a.id) ?? [];
};

const useGetCardapios = () => {
  const { data: cardapios, isLoading: isGettingCardapios } = useQuery({
    queryKey: ["cardapios"],
    queryFn,
  });

  return { cardapios, isGettingCardapios };
};

export default useGetCardapios;
