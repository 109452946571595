import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";
import useDebounce from "../../../../hooks/useDebounce";
import { useEffect } from "react";

const fetchEnderecos = async (idCliente, nome) => {
  const res = await ManjarApi.get(`/enderecos/${idCliente}`, {
    params: { nome },
  });
  return res?.data?.enderecos;
};

const useGetEnderecosByIdCliente = ({ idCliente }) => {
  const { value, debouncedValue, handleChange } = useDebounce("", 500);

  const { data, refetch, isRefetching, isLoading } = useQuery({
    queryKey: ["enderecos", idCliente, debouncedValue],
    queryFn: () => fetchEnderecos(idCliente, debouncedValue),
  });

  useEffect(() => {
    refetch();
  }, [debouncedValue]);

  return {
    data,
    isGettingEnderecos: isLoading || isRefetching,
    searchEndereco: value,
    onSearchEndereco: handleChange,
  };
};

export default useGetEnderecosByIdCliente;
