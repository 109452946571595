import { useState } from "react";
import { toast } from "react-toastify";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useUpdateCidade from "../../../api/cidade/useUpdateCidade";

const EditCidade = ({ cidade }) => {
  const [nome, setNome] = useState(cidade.nome);
  const { mutate: updateCidade, isLoading } = useUpdateCidade();

  const handleSave = (close) => {
    if (!nome) {
      toast.error("Preencha todos os campos.");
      return;
    }

    updateCidade(
      { body: { nome: getFullNameWithInitials(nome) }, id: cidade.id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      editBtn={true}
      submit={handleSave}
      buttonValue="Editar"
      isLoading={isLoading}
    >
      <div className="form-group my-3">
        <label htmlFor="nome">Nome da Cidade:</label>
        <input
          type="text"
          className="form-control"
          id="nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
      </div>
    </ModalForm>
  );
};

export default EditCidade;
