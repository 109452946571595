import "./styles.scss";
import React from "react";
import upload from "../../../assets/images/upload.png";

const UploadImage = () => {
  return (
    <div className="upload-image">
      <img
        src={upload}
        alt=""
        srcset=""
        style={{
          width: "16rem",
          height: "16rem",
          marginBottom: "4rem",
        }}
      />
      <h3>Carregar uma imagem com tamanho máximo de 2MB</h3>
    </div>
  );
};

export default UploadImage;
