import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchProduto = async (idProduto) => {
  const res = await ManjarApi.get(`/produtos/${idProduto}`);
  return { ...res?.data.produto, img: res?.data.img };
};

const useGetProduto = ({ idProduto }) => {
  const { data: produto, isLoading: isGettingProduto } = useQuery({
    queryKey: ["produto", idProduto],
    queryFn: () => fetchProduto(idProduto),
  });
  return { produto, isGettingProduto };
};

export default useGetProduto;
