import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.delete(`/enderecos/${data.id}`);
  return res.data;
};

const useDeleteEndereco = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      /* queryClient.setQueryData(["enderecos", data.id_cliente], (prev_data) =>
        prev_data?.filter((old) => old.id != data.id_endereco)
      );*/
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(
        ["enderecos", data.id_cliente],
        context.prev_data
      );
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["enderecos", data.id_cliente],
      });
    },
  });
};

export default useDeleteEndereco;
