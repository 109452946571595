import { useState } from "react";
import { toast } from "react-toastify";
import "../../../../../styles/Forms.scss";
import phone from "../../../../../assets/images/brasil.png";
import useCreateCliente from "../../../api/clientes/useCreateCliente";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";

const AddCliente = () => {
  const [cpf, setCpf] = useState();
  const [tipo, setTipo] = useState();
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [senha, setSenha] = useState("");
  const [telefone, setTelefone] = useState();
  const { createCliente, isPosttingCliente } = useCreateCliente();

  const handleSave = (close) => {
    if (!nome || !email || !telefone || !tipo || !cpf) {
      toast.error("Preencha todos os campos 🙏", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!regexEmail.test(email)) {
      toast.error("E-mail inválido 📧", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    createCliente(
      {
        tipo: tipo,
        nome: nome,
        email: email,
        telefone: "+55" + telefone,
        password: senha,
        cpf: cpf,
        auth: false,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      submit={handleSave}
      buttonValue="Adicionar cliente"
      isLoading={isPosttingCliente}
    >
      <>
        <div className=" w-100">
          <div className="form-group my-3 w-100">
            <label htmlFor="nome">Nome:</label>
            <input
              type="text"
              className="form-control"
              id="nome"
              placeholder="Ex.João de Sousa"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group my-3 w-100">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="joacastro@manjar.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
          </div>

          <div className="form-group my-3 w-100">
            <label htmlFor="senha">Senha:</label>
            <input
              type="password"
              className="form-control"
              id="senha"
              placeholder="Insira a senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
          </div>

          <div className="form-group my-3 w-100">
            <label htmlFor="telefone">Telefone:</label>
            <div className="phone-input rounded p-2">
              <span className="d-flex align-items-center">
                <img src={phone} alt="" />
                <span> +55</span>
              </span>
              <input
                type="text"
                id="telefone"
                maxLength={10}
                placeholder="Insira o número de telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group my-3 w-100">
            <label htmlFor="tipo">Tipo:</label>
            <select
              className="form-control"
              id="tipo"
              value={tipo}
              placeholder="Ex. Vegetariano"
              onChange={(e) => setTipo(e.target.value)}
            >
              <option value="">Selecione o tipo de cliente</option>
              <option value="Normal">Normal</option>
              <option value="Vegano">Vegano</option>
              <option value="Vegetariano">Vegetariano</option>
            </select>
          </div>
          <div className="form-group my-3 w-100">
            <label htmlFor="cpf">CPF:</label>
            <input
              type="text"
              className="form-control"
              id="cpf"
              maxLength={10}
              placeholder="Insira o CPF"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
          </div>
        </div>
      </>
    </ModalForm>
  );
};

export default AddCliente;
