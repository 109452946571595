import { toast } from "react-toastify";
import { fields_products } from "../fields";
import Table from "../../../components/Feature/Table";
import productimg from "../../../assets/images/product.png";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import { useViewProductsContext } from "../context/ViewProductContext";
import NormalBtn from "../../../components/Common/Buttons/NormalBtn";
import useDeleteProduto from "../api/produto/useDeletePoroduto";
import { useState } from "react";
import { useRightAnimation } from "../../../hooks/useRightAnimation";

export const Produtos = ({ produtos, isGettingProdutos }) => {
  const { activeAnimation } = useRightAnimation();
  const [idPrLoad, setIdPrLoad] = useState();
  const { setIdProduto, setShowCard } = useViewProductsContext();
  const { mutate: deleteProduto, isLoading: isDeletingProduto } =
    useDeleteProduto();

  const handleDelete = (id) => {
    setIdPrLoad(id);
    deleteProduto(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <Table
      isLoading={isGettingProdutos}
      itemsAtList={produtos ? produtos?.length : 0}
      descriptionItemList={"Não há produtos registrados"}
    >
      <ItemsRow type={"items-row-header"}>
        {fields_products.map((field) => (
          <th>{field.value !== "code" && field.label}</th>
        ))}
      </ItemsRow>
      {produtos?.map((item) => (
        <ItemsRow type={"items-row"}>
          <td className="item-cell item-code fw-bold">{item.id}</td>
          <td>
            <img src={item?.img ? item?.img.url : productimg} alt="" />
          </td>
          <td className=" fw-bold">{item.nome}</td>
          <td>{item.categoria}</td>
          <td>{item.estoque}</td>
          <td className="active-desc">R${item.preco}</td>
          <td>
            <NormalBtn
              value={"Ver"}
              onClick={() => {
                activeAnimation();
                setIdProduto(item.id);
                setShowCard(1);
              }}
            />
          </td>
          <td>
            <div className="display-item-center">
              <NormalBtn
                deleteBtn
                value={"Eliminar"}
                delectDesc={"produto"}
                onClick={() => handleDelete(item.id)}
                isLoading={item.id === idPrLoad && isDeletingProduto}
              />
            </div>
          </td>
        </ItemsRow>
      ))}
    </Table>
  );
};
