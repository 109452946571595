export const fields_planos = [
  { value: "code", label: "Código" },
  { value: "nome", label: "Nome" },
  { value: "qtd-semanal", label: "Refeições Semanais" },
  { value: "qtd-mensal", label: "Refeições Mensais" },
  { value: "preco-semanal", label: "Preço Semanal" },
  { value: "preco-mensal", label: "Preço Mensal" },
  { value: "desc", label: "Descrição" },
  { value: "edit", label: "Editar" },
  { value: "edit", label: "Eliminar" },
];

export const tabs_plans = [
  {
    label: "Planos",
    route: "planos",
  },
  {
    label: "Associar Plano a cliente",
    route: "associar",
  },
];
