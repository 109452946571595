import { createContext, useContext, useState } from "react";

const AnimationRightCard = createContext({});

export const AnimationRightCardProvider = ({ children }) => {
  const [animation, setAnimation] = useState(null);

  const activeAnimation = () => {
    setAnimation("card-right-animation");

    setTimeout(() => {
      setAnimation(null);
    }, 1000);
  };

  return (
    <AnimationRightCard.Provider
      value={{
        animation,
        activeAnimation,
      }}
    >
      {children}
    </AnimationRightCard.Provider>
  );
};

export const useRightAnimation = () => {
  return useContext(AnimationRightCard);
};
