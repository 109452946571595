import { toast } from "react-toastify";
import Table from "../../../../components/Feature/Table";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";
import { justifyTenLetter } from "../../../../utils/userUtils";
import useGetAdmins from "../../api/Admin/useGetAdmins";
import { fields_admins } from "../../fields";
import profileImg from "../../../../assets/images/profile.png";
import EditAdminForm from "../Forms/Admins/EditAdminForm";
import NormalBtn from "../../../../components/Common/Buttons/NormalBtn";
import { useState } from "react";
import useDeleteAdmin from "../../api/Admin/useDeleteAdmin";
import MasterAlert from "../../../../components/Common/Info/MasterAlert";

const Admins = () => {
  const [idAdmin, setIdAdmin] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const { admins, isGettingAdmins } = useGetAdmins();
  const { mutate: deleteAdmin, isLoading: isDeletingAdmin } = useDeleteAdmin();

  const handleDelete = (id) => {
    setIdAdmin(id);
    deleteAdmin(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <>
      <MasterAlert setShow={setShowAlert} show={showAlert} />
      <Table
        isLoading={isGettingAdmins}
        itemsAtList={admins ? admins?.length : 0}
        descriptionItemList={"Não há adminstradores registradas"}
      >
        <ItemsRow type={"items-row-header"}>
          {fields_admins.map((field) => (
            <th>{field.value !== "code" && field.label}</th>
          ))}
        </ItemsRow>
        {admins?.map((item) => (
          <ItemsRow type={"items-row"} borderColor={"red"}>
            <td className="item-cell item-code fw-bold">{item?.id}</td>
            <td>
              <img src={item?.img ?? profileImg} alt="" />
            </td>
            <td className="item-cell" style={{ fontWeight: "bold" }}>
              {justifyTenLetter(item?.nome)}
            </td>
            <td className="item-cell">{justifyTenLetter(item?.email)}</td>
            <td className="item-cell">{item?.telefone}</td>
            <td className="item-one-button">
              <EditAdminForm admin={item} />
            </td>
            <td>
              <div className="display-item-center">
                {item.master_admin === "master" ? (
                  <NormalBtn
                    backgroundColor={"green"}
                    value={"Conta principal"}
                    onClick={() => setShowAlert(true)}
                  />
                ) : (
                  <NormalBtn
                    deleteBtn
                    value={"Eliminar"}
                    onClick={() => handleDelete(item.id)}
                    delectDesc={"administrador"}
                    isLoading={item.id === idAdmin && isDeletingAdmin}
                  />
                )}
              </div>
            </td>
          </ItemsRow>
        ))}
      </Table>
    </>
  );
};

export default Admins;
