import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCliente = async (idCliente) => {
  const res = await ManjarApi.get(`/clientes/${idCliente}`);
  return { ...res?.data.cliente, img: res?.data.img };
};

const useGetClienteById = ({ idCliente }) => {
  const { data: cliente, isLoading: isGettingCliente } = useQuery({
    queryKey: ["cliente", idCliente],
    queryFn: () => fetchCliente(idCliente),
  });
  return { cliente, isGettingCliente };
};

export default useGetClienteById;
