import { createContext, useContext, useState } from "react";

const ViewProductionContext = createContext({});

export const ViewProductionContextProvider = ({ children }) => {
  const [idPlano, setIdPlano] = useState();
  const [idCliente, setIdCliente] = useState();
  const [showCard, setShowCard] = useState(null);

  return (
    <ViewProductionContext.Provider
      value={{
        idCliente,
        setIdCliente,
        showCard,
        setShowCard,
        idPlano,
        setIdPlano,
      }}
    >
      {children}
    </ViewProductionContext.Provider>
  );
};

export const useViewProductionContext = () => {
  return useContext(ViewProductionContext);
};
