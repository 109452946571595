import "../styles.scss";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Delete } from "@mui/icons-material";
import Table from "../../../../components/Feature/Table";
import useGetCategorias from "../../api/categoria/useGetCategorias";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";
import CardRight from "../../../../components/Layout/Container/CardRight";
import AddCategory from "../forms/Categoria/AddCategory";
import NormalBtn from "../../../../components/Common/Buttons/NormalBtn";
import EditCategory from "../forms/Categoria/EditCategory";
import useDeleteCategoria from "../../api/categoria/useDeleteCategoria";
import { useState } from "react";
import SmallLoading from "../../../../components/Common/SmallLoading";

const CardRightCategoria = () => {
  const [idCategoria, setIdCategoria] = useState();
  const { categorias, isGettingCategorias } = useGetCategorias();
  const { mutate: deleteCategoria, isLoading } = useDeleteCategoria();
  const handleDelete = (id) => {
    setIdCategoria(id);
    deleteCategoria(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <CardRight isLoading={isGettingCategorias}>
      <div className="display-flex-between-row">
        <h5 style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Categorias</h5>
        <AddCategory />
      </div>
      <div className="lineSolid" />
      <div className="scroll-card-right">
        <Table
          notFoundSmall
          descriptionItemList={"Lista vazia"}
          itemsAtList={categorias ? categorias.length : 0}
        >
          {categorias?.map((item) => (
            <ItemsRow type={"items-row"}>
              <td
                style={{ textAlign: "left", width: "10rem" }}
                id={`categoria${item.id}`}
              >
                <Tooltip
                  anchorSelect={`#categoria${item.id}`}
                  content={item.description}
                />
                {item.nome}
              </td>
              <td>
                <EditCategory categoria={item} />
              </td>
              <td>
                <div className="display-item-center">
                  <NormalBtn
                    deleteBtn
                    delectDesc={"categoria"}
                    iconBtn={
                      <span className="icon-btn  delete-icon-btn">
                        {item.id === idCategoria && isLoading ? (
                          <SmallLoading />
                        ) : (
                          <Delete className="icon-btn-icon" />
                        )}
                      </span>
                    }
                    onClick={() => handleDelete(item.id)}
                  />
                </div>
              </td>
            </ItemsRow>
          ))}
        </Table>
      </div>
    </CardRight>
  );
};

export default CardRightCategoria;
