import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "./context/Auth/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AnimationRightCardProvider } from "./hooks/useRightAnimation";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <AnimationRightCardProvider>
          <App />
          {process.env.NODE_ENV === "development" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </AnimationRightCardProvider>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>
);
