export const routes_users = [
  {
    route: "clientes",
    label: "Clientes",
  },
  {
    route: "administradores",
    label: "Administradores",
  },
  {
    route: "entregadores",
    label: "Entregadores",
  },
];

export const routes_entregas = [
  {
    route: "entregas",
    label: "Entregas",
  },
  {
    route: "rotas",
    label: "Rotas",
  },
];
