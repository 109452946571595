import { toast } from "react-toastify";
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import Table from "../../../components/Feature/Table";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import { fields_planos } from "../fields";
import { removePlano } from "../../../utils/planoUtils";
import useGetPlanos from "../api/useGetPlanos";
import { refatorarText } from "../../../utils/stringUtils";
import EditPlan from "./forms/PlanFormEdit";
import NormalBtn from "../../../components/Common/Buttons/NormalBtn";
import useDeletePlano from "../api/useDeletePlano";

export const Planos = () => {
  const [idPlanoLoad, setIdPlanoLoad] = useState();
  const { planos, isGettingPlanos } = useGetPlanos();
  const { mutate: deletePlano, isLoading: isDeletingPlano } = useDeletePlano();

  const handleDelete = (id) => {
    setIdPlanoLoad(id);
    deletePlano(
      { id },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <Table
      isLoading={isGettingPlanos}
      itemsAtList={planos ? planos?.length : 0}
      descriptionItemList={"Não há planos registrados"}
    >
      <ItemsRow type={"items-row-header"}>
        {fields_planos.map((field) => (
          <th>{field.label}</th>
        ))}
      </ItemsRow>
      {planos?.map((item) => (
        <ItemsRow type={"items-row"}>
          <td
            className={`fw-bold`}
            style={{
              borderLeftColor: item?.cor ?? "",
            }}
          >
            {item.id}
          </td>

          <td>
            <span
              className={`name-plan rounded `}
              style={{
                color: "white",
                backgroundColor: item?.cor ?? "",
              }}
            >
              {removePlano(item?.nome)}
            </span>
          </td>
          <td className={` ${!item?.qtd_refeicoes_semanal && "inactive-desc"}`}>
            {item?.qtd_refeicoes_semanal ?? "Não disponível"}
          </td>
          <td className={`${!item?.qtd_refeicoes_mensal && "inactive-desc"}`}>
            {item?.qtd_refeicoes_mensal ?? "Não disponível"}
          </td>
          <td className={`${item?.preco_semanal && "active-desc"}`}>
            {item?.preco_semanal
              ? `R$ ${item?.preco_semanal}`
              : "Depende da compra"}
          </td>
          <td className={`${item?.preco_mensal && "active-desc"}`}>
            {item?.preco_mensal
              ? `R$ ${item?.preco_mensal}`
              : "Depende da compra"}
          </td>
          <td id={`plano${item.id}`}>
            <Tooltip
              anchorSelect={`#plano${item.id}`}
              content={item?.descricao}
            />
            {refatorarText(item.descricao, 10)}
          </td>
          <td>
            <EditPlan plano={item} />
          </td>
          <td>
            <div
              style={{
                display: "flex",
                dignItems: "center",
                justifyContent: "center",
              }}
            >
              <NormalBtn
                deleteBtn={true}
                value={"Eliminar"}
                delectDesc={"Plano"}
                onClick={() => handleDelete(item.id)}
                isLoading={item.id === idPlanoLoad && isDeletingPlano}
              />
            </div>
          </td>
        </ItemsRow>
      ))}
    </Table>
  );
};
