import "./production.scss";
import { useState } from "react";
import MainCard from "../../components/Feature/Cards/MainCard";
import ContainerProdution from "./components/ContainerProdution";
import ProductionList from "./components/ProductionList";

const Production = () => {
  const [abaChange, setAbaChange] = useState("todos");
  const [showCard, setShowCard] = useState(false);

  return (
    <ContainerProdution
      title={"Produção"}
      showCard={showCard}
      abaChange={abaChange}
      setAbaChange={setAbaChange}
      setShowCard={() => setShowCard(showCard ? false : true)}
    >
      <MainCard className={"min-h-100"}>
        <ProductionList />
      </MainCard>
    </ContainerProdution>
  );
};

export default Production;
