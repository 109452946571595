import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";
import useDebounce from "../../../../hooks/useDebounce";
import { useEffect } from "react";

const fetchClientes = async (nome) => {
  const res = await ManjarApi.get("/clientes", {
    params: { nome },
  });
  return res?.data.clientes;
};

const useGetClientes = () => {
  const { value, debouncedValue, handleChange } = useDebounce("", 500);
  const {
    refetch,
    data: clientes,
    isRefetching,
    isLoading,
  } = useQuery({
    queryKey: ["clientes", debouncedValue],
    queryFn: () => fetchClientes(debouncedValue),
  });

  useEffect(() => {
    refetch();
  }, [debouncedValue]);

  return {
    clientes,
    isGettingClientes: isLoading || isRefetching,
    searchCliente: value,
    onSearchCliente: handleChange,
  };
};

export default useGetClientes;
