import "./styles.scss";
import React from "react";
import { toast } from "react-toastify";
import { reset } from "./api/useReset";
import { Tooltip } from "react-tooltip";
import { distribute } from "./api/useDistribute";
import NormalBtn from "../../Common/Buttons/NormalBtn";
import { fullLeftRotas } from "./api/useFullLeftRotas";

const OptionsConfig = ({ data, close }) => {
  const handleReset = () => {
    toast
      .promise(reset(), {
        pending: "Salvando...",
        success: "Salvo com sucesso 👌",
        error: "Erro ao Salvar 🤯",
      })
      .then(() => close());
  };

  const handleFullLeftRotas = () => {
    toast
      .promise(fullLeftRotas(), {
        pending: "Salvando...",
        success: "Salvo com sucesso 👌",
        error: "Erro ao salvar 🤯",
      })
      .then(() => close());
  };

  const handleDistribute = () => {
    if (data.bairros.length === 0 || data.entregadores === 0) {
      return toast.error("Selecione bairros e entregadores");
    }
    toast
      .promise(distribute(data), {
        pending: "Salvando...",
        success: "Salvando com sucesso 👌",
        error: "Erro ao salvar 🤯",
      })
      .then(() => close());
  };

  return (
    <>
      <div className="buttons-bottom">
        <Tooltip
          anchorSelect="#auto-destribute"
          content="Distribuir os endreços entre os entregadores"
        />
        <NormalBtn
          className={"mx-2"}
          id={"auto-destribute"}
          onClick={handleReset}
          value={"Auto-distribuição"}
        />
        <Tooltip
          anchorSelect="#endereco"
          content="Atribuir entregar aos endereços sem entregador"
        />
        <NormalBtn
          id={"endereco"}
          onClick={handleFullLeftRotas}
          value={"Endereços restantes"}
        />
      </div>
      <NormalBtn
        value={"Salvar"}
        onClick={handleDistribute}
        backgroundColor={"var(--saudavel)"}
      />
    </>
  );
};

export default OptionsConfig;
