import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  console.log({ ...data });
  const formData = data.image;
  const res = await ManjarApi.post(
    `/image-upload/${data.imageable_type}/${data.imageable_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

const useUloadProdutoImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      const prev_data = queryClient.getQueryData(["produtos"]);
      queryClient.setQueryData(
        ["produtos"],
        prev_data.map((old) => {
          if (old.id === data.id_entidade) {
            return { ...data, img: data.img };
          }
          return old;
        })
      );
      queryClient.setQueryData(["produto", Number(data.id_entidade)], (old) => {
        return { ...old, img: data.img };
      });
    },

    onError: (err, data, context) => {
      queryClient.setQueryData(["produtos"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["produtos"] });
    },
  });
};

export default useUloadProdutoImage;
