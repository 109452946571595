import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Users from "../pages/Users";
import Products from "../pages/Products";
import Orders from "../pages/Orders";
import Deliveries from "../pages/Deliveries";
import Cities from "../pages/Cities";
import Plans from "../pages/Plans";
import Login from "../pages/Login";
import PrivateRoute from "./PrivateRoute";
import Production from "../pages/Production";
import Chat from "../pages/Chat";
import { ViewBairrosContextProvider } from "../pages/Cities/context/ViewBairrosContext";
import { ViewProductsContextProvider } from "../pages/Products/context/ViewProductContext";
import { ViewProductionContextProvider } from "../pages/Production/context/ViewProductionContext";
import { ViewUsersContextProvider } from "../pages/Users/context/ViewUsersContext";
import Cardapio from "../pages/Cardapio";
import Privacy from "../pages/Privacy";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="/usuarios">
            <Route
              index
              element={
                <PrivateRoute>
                  <ViewUsersContextProvider>
                    <Users />
                  </ViewUsersContextProvider>
                </PrivateRoute>
              }
            />
            <Route
              element={
                <PrivateRoute>
                  <ViewUsersContextProvider>
                    <Users />
                  </ViewUsersContextProvider>
                </PrivateRoute>
              }
              path=":user_route"
            />
            <Route
              element={
                <PrivateRoute>
                  <ViewUsersContextProvider>
                    <Users />
                  </ViewUsersContextProvider>
                </PrivateRoute>
              }
              path=":user_route/:idCliente"
            />
          </Route>
          <Route path="/produtos">
            <Route
              index
              element={
                <PrivateRoute>
                  <ViewProductsContextProvider>
                    <Products />
                  </ViewProductsContextProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/entregas"
            element={
              <PrivateRoute>
                <Deliveries />
              </PrivateRoute>
            }
          />
          <Route
            path="/pedidos"
            element={
              <PrivateRoute>
                <ViewProductionContextProvider>
                  <Orders />
                </ViewProductionContextProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/cidades"
            element={
              <PrivateRoute>
                <ViewBairrosContextProvider>
                  <Cities />
                </ViewBairrosContextProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/planos"
            element={
              <PrivateRoute>
                <Plans />
              </PrivateRoute>
            }
          />
          <Route
            path="/producao"
            element={
              <PrivateRoute>
                <ViewProductionContextProvider>
                  <Production />
                </ViewProductionContextProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/cardapio"
            element={
              <PrivateRoute>
                <Cardapio />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <Chat />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
