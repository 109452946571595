import { useState } from "react";
import Container from "../../components/Layout/Container";
import ProductionList from "../Production/components/ProductionList";
import MainCard from "../../components/Feature/Cards/MainCard";
import NormalBtn from "../../components/Common/Buttons/NormalBtn";
import { useViewProductionContext } from "../Production/context/ViewProductionContext";
import CardRightDetalhes from "../Production/components/CardRightDetalhes";
import CardRightPreferrencias from "../Production/components/CardRightPreferencias";
import ProductionListFilter from "../Production/components/ProductionListFilter";
import GenerateFaturaPdf from "../Production/components/FaturaPdf/GenerateFatura";

const Orders = () => {
  const [changeTab, setChangeTab] = useState(null);
  const { showCard, setShowCard } = useViewProductionContext();

  return (
    <Container
      title={"Pedidos"}
      showCard={showCard}
      descCardContent={
        <>
          {showCard === 1 && <CardRightPreferrencias />}
          {showCard === 2 && <CardRightDetalhes />}
          {showCard === 3 && <GenerateFaturaPdf />}
        </>
      }
      buttons={
        <div className="d-flex align-items-center">
          <NormalBtn
            onClick={() => setChangeTab(null)}
            value={"Todos"}
            textColor={changeTab === null ? "white" : "black"}
            backgroundColor={
              changeTab === null ? "var(--primary)" : "lightgray"
            }
          />
          <NormalBtn
            onClick={() => setChangeTab("Plano Avulso")}
            value={"Plano Avulso"}
            textColor={changeTab === "Plano Avulso" ? "white" : "black"}
            backgroundColor={
              changeTab === "Plano Avulso" ? "var(--avulso)" : "lightgray"
            }
            className={"mx-2"}
          />
          <NormalBtn
            onClick={() => setChangeTab("Plano Saudável")}
            value={"Plano Saudável"}
            textColor={changeTab === "Plano Saudável" ? "white" : "black"}
            backgroundColor={
              changeTab === "Plano Saudável" ? "var(--saudavel)" : "lightgray"
            }
          />
          <NormalBtn
            onClick={() => setChangeTab("Plano Low Carb")}
            value={"Plano Low Carb"}
            textColor={changeTab === "Plano Low Carb" ? "white" : "black"}
            backgroundColor={
              changeTab === "Plano Low Carb" ? "var(--carb)" : "lightgray"
            }
            className={"mx-2"}
          />
          <NormalBtn
            onClick={() => setChangeTab("dieta")}
            value={"Dietas Especiais"}
            textColor={changeTab === "dieta" ? "white" : "black"}
            backgroundColor={
              changeTab === "dieta" ? "var(--dieta)" : "lightgray"
            }
          />
        </div>
      }
      setShowCard={() => setShowCard(null)}
    >
      <MainCard startTab={"todos"} className={"min-h-100"}>
        {!changeTab && <ProductionList />}
        {changeTab && <ProductionListFilter filter={changeTab} />}
      </MainCard>
    </Container>
  );
};

export default Orders;
