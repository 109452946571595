import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

export const queryFn = async () => {
  const res = await ManjarApi.get("/rotas");
  console.log(res?.data.rotas ?? []);
  return res?.data?.rotas ?? [];
};

export const useGetRotas = () => {
  const { data: rotas, isLoading: isLoadingRotas } = useQuery({
    queryKey: ["routes"],
    queryFn,
  });

  return { rotas, isLoadingRotas };
};
