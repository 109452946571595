import React from "react";

const Privacy = () => {
  return (
    <div className="mt-3 d-flex w-100 flex-column align-items-center">
      <div className="w-50">
        <h1 className="text-left">
          Política de Privacidade - Manjar Refeições
        </h1>
        <div className="my-4 card p-5 ">
          <p>
            A privacidade dos nossos usuários é extremamente importante para
            nós. Esta Política de Privacidade descreve como coletamos, usamos,
            armazenamos e protegemos as informações pessoais dos usuários.
          </p>
          <div className="d-grid gap-3">
            <div>
              <h4 className="font-weight-bold">Informações Coletadas</h4>
              <p>
                O Manjar Refeições coleta informações pessoais dos usuários,
                incluindo, mas não se limitando a: nome, endereço de e-mail,
                endereço de entrega, histórico de pedidos e informações de
                pagamento.
              </p>
            </div>
            <div>
              <h4 className="font-weight-bold">Uso das Informações</h4>
              <p>
                As informações coletadas são utilizadas para processar pedidos,
                personalizar a experiência do usuário, enviar notificações sobre
                promoções e melhorar nossos serviços.
              </p>
            </div>
            <div>
              <h4 className="font-weight-bold">
                Compartilhamento de Informações
              </h4>
              <p>
                Não compartilhamos as informações pessoais dos usuários com
                terceiros, exceto conforme necessário para processar pedidos ou
                quando exigido por lei.
              </p>
            </div>
            <div>
              <h4 className="font-weight-bold">Segurança das Informações</h4>
              <p>
                Empregamos medidas de segurança para proteger as informações
                pessoais dos usuários contra acesso não autorizado e uso
                indevido.
              </p>
            </div>
            <div>
              <h4 className="font-weight-bold">Retenção de Dados</h4>
              <p>
                As informações dos usuários serão mantidas enquanto a conta
                estiver ativa. As contas podem ser desativadas, mas as
                informações pessoais relacionadas serão retidas por motivos de
                histórico de pedidos e segurança.
              </p>
            </div>
            <div>
              <h4 className="font-weight-bold">
                Alterações na Política de Privacidade
              </h4>
              <p>
                Reservamo-nos o direito de modificar esta Política de
                Privacidade a qualquer momento. As alterações serão comunicadas
                aos usuários através de notificações no aplicativo ou por
                e-mail.
              </p>
            </div>
            <div>
              <h4 className="font-weight-bold">Contato</h4>
              <p>
                Se você tiver alguma dúvida ou preocupação sobre nossa Política
                de Privacidade, entre em contato conosco pelo e-mail:
                <span className="text-primary"> elcio34@gmail.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
