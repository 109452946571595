import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchPeferencias = async (idCliente) => {
  const res = await ManjarApi.get(`/rejeicoes_gastronomicas/${idCliente}`);
  console.log(res);
  return res?.data;
};

const useGetPeferencias = ({ idCliente }) => {
  const { data: preferencias, isLoading: isGettingPreferencias } = useQuery({
    queryKey: ["preferencias", idCliente],
    queryFn: () => fetchPeferencias(idCliente),
  });
  return { preferencias, isGettingPreferencias };
};

export default useGetPeferencias;
