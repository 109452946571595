import React from "react";

const Modal = ({ children }) => {
  return (
    <div className="positionAbsolute d-flex align-items-center justify-content-center">
      <div className="positionAbsolute-container">{children}</div>
    </div>
  );
};

export default Modal;
