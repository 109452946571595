import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const mutationFn = async (singleFile, id_plano) => {
  const formData = singleFile.image;

  const response = await ManjarApi.post(
    `/image-cardapio-upload/${id_plano}`,
    formData,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response?.data;
};

const useUploadImageCardapio = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadImageCardapio, isLoading: isUploadingCardapioImage } =
    useMutation({
      mutationFn: (data) => mutationFn(data, data.id_plano),
      onSuccess: (data) => {
        queryClient.setQueryData(["cardapios"], (old = []) => {
          const newArray = [...old];
          newArray.unshift(data);
          return newArray;
        });
      },
    });

  return {
    uploadImageCardapio,
    isUploadingCardapioImage,
  };
};

export default useUploadImageCardapio;
