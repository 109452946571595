import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

export const updateOrderEntregas = async (data) => {
  const res = await ManjarApi.post("/update-positions-entregas", data);
  return res.data?.entregas;
};

export const useUpdateEntregasPositions = () => {
  const queryClient = useQueryClient();

  const { mutate: updateEntregasPositions, isLoading } = useMutation({
    mutationFn: (data) => updateOrderEntregas(data),
    onSuccess: async (data) => {
      queryClient.setQueryData("entregas", [...data]);
    },
    onError: (error, variables, context) => {
      // Em caso de erro, reverta para o estado anterior
      if (context?.previousData) {
        queryClient.setQueryData("entregas", context.previousData);
      }
    },
    onSettled: () => {
      // A mutação foi concluída, então refaça a query "entregas" para obter dados atualizados
      queryClient.invalidateQueries("entregas");
    },
  });

  return { updateEntregasPositions, isLoading };
};
