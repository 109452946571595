import Modal from "..";
import "./styles.scss";
import React from "react";
import { CloseOutlined } from "@mui/icons-material";

const ModalBase = ({ setShow, children }) => {
  return (
    <Modal>
      <div className="modal-base-container">
        <button className="close-btn" onClick={() => setShow(false)}>
          <CloseOutlined
            style={{ height: "20px", width: "20px", color: "white" }}
          />
        </button>
        <div className="break-top" />
        {children}
      </div>
    </Modal>
  );
};

export default ModalBase;
