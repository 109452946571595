import "./styles.scss";
import useAuth from "../../../../hooks/UseAuth";
import { Logout } from "@mui/icons-material";
import { useState } from "react";
import { logout } from "../../../../services/auth";

const DropDownUser = () => {
  const auth = useAuth();
  const [isLogout, setIsLogout] = useState(false);

  const handleLogout = async () => {
    setIsLogout(true);
    await logout();
    auth.logout();
    setIsLogout(false);
    window.location.reload();
  };

  return (
    <div className="dropDownUser">
      <div className="w-100 d-flex -items-center">
        <button
          disabled={isLogout}
          type="button"
          className="position-relative d-flex justify-content-center w-100 btnLogout"
          onClick={handleLogout}
        >
          <div className={`${isLogout ? "isLogout" : ""}`} />
          <Logout className="iconNavbar" /> Sair
        </button>
      </div>
    </div>
  );
};

export default DropDownUser;
