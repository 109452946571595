import Loading from "../../Common/Loading";
import SearchNotFounded from "../../Common/SeachNotFounded";
import "./styles.scss";

const Table = ({
  children,
  isLoading,
  itemsAtList,
  notFoundSmall,
  descriptionItemList,
}) => {
  return isLoading ? (
    <div className="app-item-center">
      <Loading />
    </div>
  ) : itemsAtList === 0 ? (
    <div
      className={`${
        notFoundSmall ? "app-item-center-right" : "app-item-center"
      }`}
    >
      <SearchNotFounded
        small={notFoundSmall}
        description={descriptionItemList}
      />
    </div>
  ) : (
    <table className="table-app">{children}</table>
  );
};

export default Table;
