import "./styles.scss";
import React from "react";
import { Close } from "@mui/icons-material";

const DescCard = ({ children, setShow, className, notShowCloseBtn }) => {
  return (
    <div className={"card-app desc-card " + className}>
      {!notShowCloseBtn && (
        <button
          onClick={setShow}
          className="close-btn"
          style={{
            borderRadius: "10px",
          }}
        >
          <Close
            className="icon"
            style={{
              height: "20px",
              width: "20px",
              color: "white",
              borderRadius: "10px",
            }}
          />
        </button>
      )}
      <div style={{ marginTop: "1.5rem" }} />
      {children}
    </div>
  );
};

export default DescCard;
