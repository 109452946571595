export const fields_clientes = [
  { value: "code", label: "Código" },
  { value: "img", label: "Imagem" },
  { value: "nome ", label: "Nome" },
  { value: "email", label: "Email" },
  { value: "telefone ", label: "Telefone" },
  { value: "tipo_plano", label: "Tipo plano" },
  { value: "plano", label: "Plano" },
  { value: "one_btn", label: "Detalhes" },
  { value: "turnOfAccount", label: "Desactivar conta" },
];

export const fields_admins = [
  { value: "code", label: "Código" },
  { value: "img", label: "Imagem" },
  { value: "nome ", label: "Nome" },
  { value: "email", label: "Email" },
  { value: "telefone", label: "Telefone" },
  { value: "one_btn", label: "Editar" },
  { value: "one_btn", label: "Remover" },
];

export const fields_funcionarios = [
  { value: "code", label: "Código" },
  { value: "user_code", label: "Código de Usuário" },
  { value: "nome", label: "Nome" },
  { value: "password", label: "Password" },
  { value: "btn", label: "Editar" },
  { value: "eliminar", label: "Eliminar" },
];

export const fields_entregadores = [
  { value: "code", label: "Código" },
  { value: "estado_entregador", label: "Estado" },
  { value: "nome", label: "Nome" },
  { value: "btn", label: "Ver Rota" },
  { value: "btn", label: "Editar" },
  { value: "btn", label: "Eliminar" },
];

export const fields_address = [
  { value: "code", label: "Código" },
  { value: "cep", label: "CEP" },
  { value: "entregador", label: "Entregador" },
  { value: "cidade", label: "Cidade" },
  { value: "bairro", label: "Bairro" },
  { value: "endereco", label: "Endereço" },
  { value: "numero", label: "Número" },
  { value: "complemento", label: "Complemento" },
  { value: "btn", label: "Editar" },
  { value: "remover", label: "Remover" },
];
