/* eslint-disable react/prop-types */
import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useNavigate } from "react-router-dom";
import { BalanceOutlined } from "@mui/icons-material";

const Widget = ({ type, value }) => {
  let data;

  const navigate = useNavigate();

  //temporary
  //const amount = 100;
  const diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "Total de Clientes",
        isMoney: false,
        value: value,
        link: "ver todos usuários",
        url: "/usuarios",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "white",
              backgroundColor: "crimson",
            }}
          />
        ),
      };
      break;
    case "deliver":
      data = {
        title: "Entregas",
        isMoney: false,
        value: 5,
        url: "/entregas",
        link: "ver todas entregas",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              color: "white",
              backgroundColor: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "Total de Vendas",
        isMoney: true,
        value: 5,
        url: "",
        link: "",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "green", color: "white" }}
          />
        ),
      };
      break;
    case "earning total":
      data = {
        title: "Vendas Por Planos",
        isMoney: true,
        value: 5,
        url: "",
        link: "",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "green", color: "white" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "Saldo De Vendas",
        isMoney: true,
        value: 500,
        url: "/users",
        link: "",
        icon: (
          <BalanceOutlined
            className="icon"
            style={{
              color: "white",
              backgroundColor: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "R$"} {data.value}
        </span>
        {data.link && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(data.url)}
            className="link"
          >
            {data.link}
          </span>
        )}
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
