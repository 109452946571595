import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Jan", Total: 1200 },
  { name: "Fev", Total: 2100 },
  { name: "Mar", Total: 800 },

  { name: "Abr", Total: 1600 },
  { name: "Mai", Total: 900 },
  { name: "Jun", Total: 1700 },

  { name: "Jul", Total: 100 },
  { name: "Ago", Total: 1700 },
  { name: "Set", Total: 1800 },

  { name: "Out", Total: 500 },
  { name: "Nov", Total: 200 },
  { name: "Dez", Total: 1900 },
];

const Chart = ({ aspect, title }) => {
  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer aspect={aspect} className="w-100">
        <AreaChart
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="100%" stopColor="#45C4B0" stopOpacity={0.7} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="purple"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
