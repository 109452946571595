import Modal from "..";
import "./styles.scss";
import React, { useState } from "react";
import NormalBtn from "../../Buttons/NormalBtn";
import { CloseOutlined } from "@mui/icons-material";

const ModalForm = ({
  children,
  submit,
  buttonValue,
  smallBtn,
  isLoading,
  editBtn,
  iconBtn,
}) => {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      {smallBtn ? (
        <button onClick={() => setShowForm(true)} className="small-btn">
          {buttonValue}
        </button>
      ) : iconBtn ? (
        <div onClick={() => setShowForm(true)}>{iconBtn}</div>
      ) : (
        <NormalBtn
          value={buttonValue}
          editBtn={editBtn}
          onClick={() => setShowForm(true)}
        />
      )}
      {showForm && (
        <Modal>
          <div className="form-container">
            <button className="close-btn" onClick={() => setShowForm(false)}>
              <CloseOutlined
                style={{ height: "20px", width: "20px", color: "white" }}
              />
            </button>
            <div className="break-top" />
            <div className="form-conainer-inputs">{children}</div>
            <div className="form-submit-btn">
              <NormalBtn
                value="Salvar"
                onClick={() => submit(() => setShowForm(false))}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalForm;
