import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import { Edit } from "@mui/icons-material";
import useUpdateBairro from "../../../api/bairro/useUpdateBairro";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";

const EditNeighborhood = ({ bairro }) => {
  const [nome, setNome] = useState(bairro?.nome);
  const { mutate: updateBairro, isLoading } = useUpdateBairro();

  const handleSave = (close) => {
    if (!nome) {
      toast.error("Preencha todos os campos.");
      return;
    }
    updateBairro(
      {
        body: { nome: getFullNameWithInitials(nome) },
        id: bairro.id,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      iconBtn={
        <span className="icon-btn  edit-icon-btn">
          <Edit className="icon-btn-icon" />
        </span>
      }
      submit={handleSave}
      isLoading={isLoading}
    >
      <div className="form-group my-3">
        <label htmlFor="nome">Nome do bairro:</label>
        <input
          type="text"
          className="form-control"
          id="nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
      </div>
    </ModalForm>
  );
};

export default EditNeighborhood;
