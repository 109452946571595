import { createContext, useContext, useState } from "react";

const ViewBairrosContext = createContext({});

export const ViewBairrosContextProvider = ({ children }) => {
  const [idCidade, setIdCidade] = useState();
  const [showCard, setShowCard] = useState(false);

  return (
    <ViewBairrosContext.Provider
      value={{ idCidade, setIdCidade, showCard, setShowCard }}
    >
      {children}
    </ViewBairrosContext.Provider>
  );
};

export const useViewBairros = () => {
  return useContext(ViewBairrosContext);
};
