import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useCreateEntregador from "../../../api/Entregador/useCreateEntregador";

const AddEntregadorForm = () => {
  const [nome, setNome] = useState("");
  const { mutate: createEntregador, isLoading: isCreatingEntregador } =
    useCreateEntregador();

  const handleSave = (close) => {
    if (!nome) {
      toast.error("Insira o nome🙏", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    createEntregador(
      {
        name: nome,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      submit={handleSave}
      buttonValue="Adicionar entregador"
      isLoading={isCreatingEntregador}
    >
      <>
        <div className=" w-100">
          <div className="form-group my-3 w-100">
            <label htmlFor="nome">Nome:</label>
            <input
              type="text"
              className="form-control"
              id="nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
        </div>
      </>
    </ModalForm>
  );
};

export default AddEntregadorForm;
