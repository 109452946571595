import "./styles.scss";
import UploadImage from "../../Common/UploadImage";

const MenuForm = ({ handleImageChange, image }) => {
  return (
    <div className="MenuForm">
      <div className="upload-image-bottom">
        <input
          type="file"
          id="imageInput"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </div>
      <div className="d-flex justify-content-center w-100">
        <div className="upload-image-bottom">
          {!!image && (
            <img
              src={image && image}
              alt=""
              className="my-4 img-fluid rounded"
              style={{ maxWidth: "100%" }}
            />
          )}
        </div>
      </div>
      {!image && <UploadImage />}
    </div>
  );
};

export default MenuForm;
