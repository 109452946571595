import { useState } from "react";
import { toast } from "react-toastify";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useCreateCidade from "../../../api/cidade/useCreateCidade";

const AddCities = () => {
  const [nome, setNome] = useState("");
  const { createCidade, isPosttingCidade } = useCreateCidade();

  const handleSave = (close) => {
    if (!nome) {
      toast.error("Preencha todos os campos.");
      return;
    }

    createCidade(
      { nome: getFullNameWithInitials(nome) },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      submit={handleSave}
      buttonValue="Adicionar Cidade"
      isLoading={isPosttingCidade}
    >
      <div className="form-group my-3">
        <label htmlFor="nome">Nome da Cidade:</label>
        <input
          type="text"
          className="form-control"
          id="nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
      </div>
    </ModalForm>
  );
};

export default AddCities;
