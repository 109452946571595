import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.delete(`/bairros/${data.id}`);
  return res.data;
};

const useDeleteBairro = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      console.log(data);

      queryClient.setQueryData(["bairros", data.id_cidade], (prev_data) => {
        return {
          cidade: prev_data.cidade,
          bairros: prev_data?.bairros.filter(
            (old) => old.id !== data.id_bairro
          ),
        };
      });
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["bairros", data.idCidade], context.prev_data);
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["bairros", data.idCidade],
      });
    },
  });
};

export default useDeleteBairro;
