import { Produtos } from "./components/ProductLIst";
import Container from "../../components/Layout/Container";
import MainCard from "../../components/Feature/Cards/MainCard";
import NormalBtn from "../../components/Common/Buttons/NormalBtn";
import CardRightProduto from "./components/RightCards/CardRightProduto";
import CardRightCategoria from "./components/RightCards/CardRightCategorias";
import { useViewProductsContext } from "./context/ViewProductContext";
import AddProduct from "./components/forms/Produto/AddProduct";
import useGetProdutos from "./api/produto/useGetProdutos";

const Products = () => {
  const { produtos, isGettingProdutos, searchProduto, onSearchProduto } =
    useGetProdutos();
  const { showCard, setShowCard } = useViewProductsContext();

  return (
    <Container
      title="Produtos"
      buttons={<ProductsBtn />}
      showCard={showCard}
      descCardContent={
        <>
          {showCard === 1 && <CardRightProduto />}
          {showCard === 2 && <CardRightCategoria />}
        </>
      }
      setShowCard={() => setShowCard(null)}
    >
      <MainCard
        searchValue={searchProduto}
        onSearchValueChange={onSearchProduto}
      >
        <Produtos produtos={produtos} isGettingProdutos={isGettingProdutos} />
      </MainCard>
    </Container>
  );
};

const ProductsBtn = () => {
  const { setShowCard } = useViewProductsContext();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <NormalBtn
        className="mx-3"
        value="Categória"
        onClick={() => setShowCard(2)}
      />
      <AddProduct />
    </div>
  );
};

export default Products;
