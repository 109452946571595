import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";
import { useEffect } from "react";
import useDebounce from "../../../../hooks/useDebounce";

const queryFn = async (nome) => {
  const res = await ManjarApi.get("/admins", {
    params: { nome },
  });
  return res?.data?.admins;
};

const useGetAdmins = () => {
  const { value, debouncedValue, handleChange } = useDebounce("", 500);

  const {
    refetch,
    data: admins,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["admins", debouncedValue],
    queryFn: () => queryFn(debouncedValue),
  });

  useEffect(() => {
    refetch();
  }, [debouncedValue]);

  return {
    admins,
    isGettingAdmins: isLoading || isRefetching,
    searchAdmin: value,
    onSearchAdmin: handleChange,
  };
};

export default useGetAdmins;
