import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.put(`/enderecos/${data.id}`, {
    ...data.body,
  });

  return res.data?.endereco;
};

const useUpdateEndereco = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      const prev_data = queryClient.getQueryData([
        "enderecos",
        data.id_cliente,
      ]);

      // Substituir a instância antiga do objeto pelo novo objeto
      queryClient.setQueryData(
        ["enderecos", data.id_cliente],
        [...prev_data?.map((old) => (old.id === data.id ? data : old))]
      );
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(
        ["enderecos", data.id_cliente],
        context.prev_data
      );
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["enderecos", data.id_cliente],
      });
    },
  });
};

export default useUpdateEndereco;
