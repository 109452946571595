import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCategoria = async (data) => {
  const res = await ManjarApi.post("/categorias", data);
  return res;
};

const useCreateCategoria = () => {
  const queryClient = useQueryClient();
  const { mutate: createCategoria, isLoading: isPosttingCategroia } =
    useMutation({
      mutationFn: (data) => fetchCategoria(data),
      onMutate: async (data) => {
        await queryClient.cancelQueries({
          queryKey: ["categorias"],
        });
        const prev_data = queryClient.getQueryData(["categorias"]);
        queryClient.setQueryData(["categorias"], (old) => [...old, data]);

        return { prev_data };
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(["categorias"], context.prev_data);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["categorias"] });
      },
    });
  return { createCategoria, isPosttingCategroia };
};

export default useCreateCategoria;
