import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchFatura = async (idPlano) => {
  const res = await ManjarApi.get(`/plano-assinado/fatura/${idPlano}`);
  return res?.data;
};

const useGetFatura = ({ idPlano }) => {
  const { data: fatura, isLoading: isGettingFatura } = useQuery({
    queryKey: ["more-fatura", idPlano],
    queryFn: () => fetchFatura(idPlano),
  });
  return { fatura, isGettingFatura };
};

export default useGetFatura;
