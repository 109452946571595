import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchUserChat = async (id) => {
  const response = await ManjarApi.get(`/chat/${id}`);
  return response?.data?.admin_chat ?? [];
};

const useGetAdminChat = (id) => {
  const {
    data: adminChat,
    isLoading: isGettingAdminChat,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["admin_chat", id],
    queryFn: () => fetchUserChat(id),
  });
  return {
    adminChat,
    isGettingAdminChat: isRefetching || isGettingAdminChat,
    refetch,
  };
};

export default useGetAdminChat;
