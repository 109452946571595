export const removePlano = (value) => {
  const newValue = value.replace("Plano", " ");
  return newValue;
};

export const refactorPlanoClassNameBorder = (plano) =>
  plano.includes("Saudável")
    ? "saudavel-bord"
    : plano.includes("Avulso")
    ? "avulso-bord"
    : "carb-bord";

export const refactorPlanoClassName = (plano) =>
  plano.includes("Saudável")
    ? "saudavel"
    : plano.includes("Avulso")
    ? "avulso"
    : "carb";
