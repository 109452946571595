import { useState } from "react";
import { useParams } from "react-router-dom";
import Admins from "./components/list/Admins";
import Clientes from "./components/list/Clientes";
import Enderecos from "./components/list/Enderecos";
import { routes_users } from "../../routes/routesTabs";
import Entregadores from "./components/list/Entregadores";
import Container from "../../components/Layout/Container";
import MainCard from "../../components/Feature/Cards/MainCard";
import { useViewUsersContext } from "./context/ViewUsersContext";
import AddCliente from "./components/Forms/Clientes/AddClienteForm";
import CardRightClientes from "./components/cardsRight/CardRightClientes";
import AddEnderecoForm from "./components/Forms/Endereco/AddressForm";
import AddAdminForm from "./components/Forms/Admins/AdminForm";
import AddEntregadorForm from "./components/Forms/Entregadores/EntregadorForm";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";
import useGetClientes from "./api/clientes/useGetClientes";
import useGetEnderecosByIdCliente from "./api/clientes/useGetEnderecoByIdCliente";

const Users = () => {
  const { user_route: route, idCliente } = useParams();
  const { changeRoute, setChangeRoute } = useState("");
  const { showCard, setShowCard } = useViewUsersContext();
  const { clientes, isGettingClientes, searchCliente, onSearchCliente } =
    useGetClientes();

  const {
    data: enderecos,
    isGettingEnderecos,
    searchEndereco,
    onSearchEndereco,
  } = useGetEnderecosByIdCliente({
    idCliente,
  });

  return (
    <Container
      title={route === "enderecos" ? "Endereços" : "Usuários"}
      showCard={showCard}
      buttons={
        <>
          {route === "entregadores" ? (
            <AddEntregadorForm />
          ) : route === "administradores" ? (
            <AddAdminForm />
          ) : route === "enderecos" ? (
            <AddEnderecoForm />
          ) : (
            <AddCliente />
          )}
        </>
      }
      descCardContent={<>{showCard === 1 && <CardRightClientes />}</>}
      setShowCard={() => setShowCard(null)}
    >
      <MainCard
        headRoute={"usuarios"}
        changeRoute={changeRoute}
        setChangeTab={setChangeRoute}
        cardLabel={
          route === "enderecos" && (
            <Link to="/usuarios">
              <div className="d-flex align-items-center">
                <ArrowBack />
              </div>
            </Link>
          )
        }
        routes={route !== "enderecos" && routes_users}
        searchValue={
          route === "entregadores" ? (
            <AddEntregadorForm />
          ) : route === "administradores" ? (
            <AddAdminForm />
          ) : route === "enderecos" ? (
            searchEndereco
          ) : (
            searchCliente
          )
        }
        onSearchValueChange={
          route === "entregadores" ? (
            <AddEntregadorForm />
          ) : route === "administradores" ? (
            <AddAdminForm />
          ) : route === "enderecos" ? (
            onSearchEndereco
          ) : (
            onSearchCliente
          )
        }
      >
        {route === "entregadores" ? (
          <Entregadores />
        ) : route === "administradores" ? (
          <Admins />
        ) : route === "enderecos" ? (
          <Enderecos
            enderecos={enderecos}
            isGettingEnderecos={isGettingEnderecos}
          />
        ) : (
          <Clientes clientes={clientes} isGettingClientes={isGettingClientes} />
        )}
      </MainCard>
    </Container>
  );
};

export default Users;
