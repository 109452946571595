import { useState } from "react";
import Container from "../../components/Layout/Container";
import MainCard from "../../components/Feature/Cards/MainCard";
import { Planos } from "./components/PlanList";
import { tabs_plans } from "./fields";
import AddPlan from "./components/forms/PlanForm";

const Plans = () => {
  const [changeTab, setChangeTab] = useState("planos");
  const [showForm, setShowForm] = useState(false);

  return (
    <Container title={"planos"} buttons={<AddPlan />}>
      <MainCard
        tabs={tabs_plans}
        changeTab={changeTab}
        setChangeTab={setChangeTab}
      >
        <Planos />
      </MainCard>
    </Container>
  );
};

export default Plans;
