import "../../styles.scss";
import useGetCardapios from "../../api/useGetCardapios";
import Table from "../../../../components/Feature/Table";
import { postedTime } from "../../../../utils/dateUtils";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";
import CardRight from "../../../../components/Layout/Container/CardRight";

const CardRightCardapio = () => {
  const { cardapios, isGettingCardapios } = useGetCardapios();

  return (
    <CardRight isLoading={isGettingCardapios}>
      <div className="display-flex-between-row">
        <h5 style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Histórico</h5>
      </div>
      <div className="lineSolid" />
      <div className="scroll-card-right">
        <Table
          notFoundSmall
          descriptionItemList={"Lista vazia"}
          itemsAtList={cardapios ? cardapios.length : 0}
        >
          {cardapios?.map((item) => (
            <ItemsRow type={"items-row"}>
              <td className="cardapio-img-row">
                <img
                  src={item.img}
                  alt="cardapio-img"
                  className="cardapio-img"
                />
              </td>
              <td
                style={{ textAlign: "left", width: "10rem" }}
                id={`categoria${item.id}`}
              >
                {item.plano}
                <br />
                <span className="cardapio-time">
                  {postedTime(item.created_at)}
                </span>
              </td>
            </ItemsRow>
          ))}
        </Table>
      </div>
    </CardRight>
  );
};

export default CardRightCardapio;
