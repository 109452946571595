import "./styles/Global.scss";
import AppRoutes from "./routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryCache } from "react-query";

export const queryClient = new QueryClient();

export const queryCache = new QueryCache();

const App = () => {
  return (
    <div>
      <AppRoutes />
      <ToastContainer />
    </div>
  );
};

export default App;
