/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from "react";
import {
  LoginRequest,
  getUserLocalStorage,
  setUserLocalStorage,
} from "../../services/auth";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const authenticate = async (email, password) => {
    const request = await LoginRequest(email, password);

    const payload = { token: request.token, email };

    setUser(payload);
    setUserLocalStorage(payload);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("urlImage");
  };

  useEffect(() => {
    const user = getUserLocalStorage();
    if (user) setUser(user);
  }, []);

  return (
    <AuthContext.Provider value={{ ...user, authenticate, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
