import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ManjarAuthApi from "../../../services/api/ManjarAuthApi";

const login_admin = (data) => ManjarAuthApi.post("/admin-login", data);
const login_funcionario = (data) => ManjarAuthApi.post("/admin-login", data);

export const useLoginApi = () => {
  const navigate = useNavigate();
  const login = (data) => {
    if (data.email && data.password) {
      if (data.email.includes("@")) {
        toast
          .promise(login_admin(data), {
            pending: "Carregando",
            success: "Logado com sucesso 👌",
            error: "Email ou password incorreto🤯",
          })
          .then(({ data }) => {
            localStorage.setItem("token", data.token);
            navigate("/");
            window.location.reload();
          });
      } else {
        toast
          .promise(login_funcionario(data), {
            pending: "Carregando",
            success: "Logado com sucesso 👌",
            error: "Código de usuário ou data.password incorreto!🤯",
          })
          .then((data) => {
            console.log(data);
            localStorage.setItem("user_code", data.email);
            navigate("/producao");
            window.location.reload();
          });
      }
    } else {
      toast.warn("Preencha todos os campos!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return { login };
};
