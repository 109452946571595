import { createContext, useContext, useState } from "react";

const ChooseClienteContext = createContext({});

export const ChooseClienteContextProvider = ({ children }) => {
  const [clienteId, setClienteChatId] = useState();

  return (
    <ChooseClienteContext.Provider
      value={{
        clienteId,
        setClienteChatId,
      }}
    >
      {children}
    </ChooseClienteContext.Provider>
  );
};

export const useChooseCliente = () => {
  return useContext(ChooseClienteContext);
};
