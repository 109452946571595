import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCliente = async (data) => {
  const res = await ManjarApi.post("/clientes", data);
  return res;
};

const useCreateCliente = () => {
  const queryClient = useQueryClient();
  const { mutate: createCliente, isLoading: isPosttingCliente } = useMutation({
    mutationFn: (data) => fetchCliente(data),
    onSuccess: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ["clientes"],
      });

      queryClient.setQueryData(["clientes"], (old) => [...old, data]);
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["clientes"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["clientes"] });
    },
  });
  return { createCliente, isPosttingCliente };
};

export default useCreateCliente;
