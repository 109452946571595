import axios from "axios";

const token = localStorage.getItem("token"); // Substitua pelo seu token de autenticação

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

const ManjarAuthApi = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_URL,
});

export default ManjarAuthApi;
