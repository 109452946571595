import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useGetCategorias from "../../../api/categoria/useGetCategorias";
import SmallLoading from "../../../../../components/Common/SmallLoading";
import useUpdateProduto from "../../../api/produto/useUpdateProduto";

const EditProduct = ({ produto }) => {
  const [nome, setNome] = useState(produto.nome);
  const [preco, setPreco] = useState(produto.preco);
  const [desc, setDesc] = useState(produto.description);
  const [estoque, setEstoque] = useState(produto.estoque);
  const [categoria, setCategoria] = useState(produto.id_categoria);
  const { categorias, isGettingCategorias } = useGetCategorias();
  const { mutate: updateProduto, isLoading } = useUpdateProduto();

  const handleSubmit = (close) => {
    if (!nome || !categoria || !desc || !categorias) {
      toast.error("Preencha todos os campos.");
      return;
    }
    updateProduto(
      {
        body: {
          description: desc,
          preco: Number(preco),
          stock: Number(estoque),
          nome: getFullNameWithInitials(nome),
          idCategoria: Number(categoria),
        },
        id: produto.id,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      editBtn
      submit={handleSubmit}
      buttonValue="Editar Produto"
      isLoading={isLoading}
    >
      <>
        <div className="mb-3">
          <div className="mb-3">
            <label htmlFor="nome" className="form-label">
              Nome:
            </label>
            <input
              id="nome"
              type="text"
              className="form-control"
              placeholder="Ex. Salada"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <label htmlFor="categoria" className="form-label">
            Categoria:
          </label>
          <select
            id="categoria"
            className="form-select"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          >
            <option value="">
              {isGettingCategorias ? <SmallLoading /> : "Selecione a categoria"}
            </option>
            {categorias?.map((item) => (
              <option value={item.id}>{item.nome}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="estoque" className="form-label">
            Estoque:
          </label>
          <input
            type="number"
            id="estoque"
            value={estoque}
            className="form-control"
            onChange={(e) => setEstoque(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="preco" className="form-label">
            Preço:
          </label>
          <input
            type="number"
            id="preco"
            className="form-control"
            value={preco}
            onChange={(e) => setPreco(e.target.value)}
          />
        </div>
        <div className="form-group my-3">
          <label htmlFor="desc">Descrição:</label>
          <textarea
            id="desc"
            name="desc"
            value={desc}
            className="form-control"
            placeholder="Adiciona uma descrição para o produto"
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </>
    </ModalForm>
  );
};

export default EditProduct;
