import "./styles.scss";
import { Search } from "@mui/icons-material";

const SearchInput = ({ value, onChange }) => {
  return (
    <div className="search-user-chat ">
      <span className="icon-search">
        <Search style={{ color: "gray", height: "20px" }} />
      </span>
      <input
        type="text"
        value={value}
        className="search-input"
        placeholder="Procurar usuário..."
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
