import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.post("/entregadores", data);
  return res.data?.entregador;
};

const useCreateEntregador = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      queryClient.setQueryData(["entregadores"], (old) => [...old, data]);
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["entregadores"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["entregadores"] });
    },
  });
};

export default useCreateEntregador;
