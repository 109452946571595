import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.patch(`/categorias/${data.id}`, { ...data.body });
  return res.data.categoria;
};

const useUpdateCategoria = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      queryClient.setQueryData(["categorias"], (prev_data) => {
        prev_data?.map((old) => {
          if (old.id === data.id) {
            return data;
          }
          return old;
        });
      });
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["categorias"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["categorias"] });
    },
  });
};

export default useUpdateCategoria;
