export const fields_production = [
  { value: "code", label: "Código" },
  { value: "cliente", label: "Cliente" },
  { value: "plano", label: "Plano" },
  { value: "endereco", label: "Endereço" },
  { value: "entregador", label: "Entregador" },
  { value: "preferencias", label: "Preferencias" },
  { value: "detalhes", label: "Detalhes" },
  { value: "seg", label: "Seg" },
  { value: "ter", label: "Ter" },
  { value: "qua", label: "Qua" },
  { value: "qui", label: "Qui" },
  { value: "sex", label: "Sex" },
];
