import "./styles.scss";
import React, { useState } from "react";
import profile from "../../../assets/images/profile.png";
import DropDownUser from "./DropDownUser";

const Avatar = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="position-relative">
      <div className="avatar" onClick={() => setOpen(open ? false : true)}>
        <img src={profile} alt="" />
      </div>
      {open && <DropDownUser />}
    </div>
  );
};

export default Avatar;
