import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const response = await ManjarApi.post("/chat/send", data);
  return response?.data?.chat;
};

const useSendMessage = () => {
  const queryClient = useQueryClient();
  const { mutate: sendMessage, isLoading: isSendingMessage } = useMutation({
    mutationFn: (data) => mutationFn(data),
    onSuccess: (data) => {
      queryClient.setQueryData(["admin_chat", data.id_cliente], (old) => [
        ...old,
        data,
      ]);
    },
  });

  return { sendMessage, isSendingMessage };
};

export default useSendMessage;
