import { NavLink } from "react-router-dom";
import "./styles.scss";
import {
  CreditCardRounded,
  Dashboard,
  Fastfood,
  Group,
  ListAlt,
  LocalShippingRounded,
  LocationCityRounded,
} from "@mui/icons-material";
import { RestaurantMenu } from "@mui/icons-material";

const Navigation = () => {
  return (
    <nav className="navigation">
      <NavLink exact to="/" activeClassName="active">
        <div className="d-flex align-items-center">
          <Dashboard className="icon" style={{ color: "blueviolet" }} /> Início
        </div>
      </NavLink>

      <NavLink to="/usuarios" activeClassName="active">
        <div className="d-flex align-items-center">
          <Group className="icon" style={{ color: "cadetblue" }} /> Usuários
        </div>
      </NavLink>

      <NavLink to="/produtos" activeClassName="active">
        <div className="d-flex align-items-center">
          <Fastfood className="icon" style={{ color: "chocolate" }} />
          Produtos
        </div>
      </NavLink>

      <NavLink to="/pedidos" activeClassName="active">
        <div className="d-flex align-items-center">
          <ListAlt className="icon" style={{ color: "green" }} />
          Pedidos
        </div>
      </NavLink>

      <NavLink to="/entregas" activeClassName="active">
        <div className="d-flex align-items-center">
          <LocalShippingRounded className="icon" style={{ color: "tomato" }} />{" "}
          Entregas
        </div>
      </NavLink>

      <NavLink to="/cidades" activeClassName="active">
        <div className="d-flex align-items-center">
          <LocationCityRounded className="icon" style={{ color: "purple" }} />
          Cidades
        </div>
      </NavLink>

      <NavLink to="/planos" activeClassName="active">
        <div className="d-flex align-items-center">
          <CreditCardRounded className="icon" style={{ color: "rosybrown" }} />{" "}
          Planos
        </div>
      </NavLink>

      <NavLink to="/cardapio" activeClassName="active">
        <div className="d-flex align-items-center">
          <RestaurantMenu className="icon" style={{ color: "orange" }} />{" "}
          Cardápios
        </div>
      </NavLink>
    </nav>
  );
};

export default Navigation;
//v3.1.0
