import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.delete(`/admins/${data.id}`);
  return res.data;
};

const useDeleteAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      console.log(data);
      queryClient.setQueryData(["admins"], (prev_data) =>
        prev_data?.filter((old) => old.id !== data.id)
      );
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["admins"], context.prev_data);
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["admins"],
      });
    },
  });
};

export default useDeleteAdmin;
