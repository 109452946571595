import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCliente = async (data) => {
  const res = await ManjarApi.patch(`/clientes/${data.id}`, { ...data.body });
  return res.data.cliente;
};

const useUpdateCliente = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => fetchCliente(data),
    onSuccess: async (data) => {
      const prev_data = queryClient.getQueryData(["clientes"]);
      queryClient.setQueryData(
        ["clientes"],
        prev_data.map((old) => {
          if (old.id === data.id) {
            return { ...data, img: old.img };
          }
          return old;
        })
      );

      queryClient.setQueryData(["cliente", data.id], (old) => {
        return { ...data, img: old.img };
      });
    },

    onError: (err, data, context) => {
      queryClient.setQueryData(["clientes"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["clientes"] });
    },
  });
};

export default useUpdateCliente;
