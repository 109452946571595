import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.post("/enderecos", { ...data });
  return res.data?.endereco;
};

const useCreateEndereco = () => {
  const queryClient = useQueryClient();

  const { mutate: createEndereco, isLoading: isPosttingEndereco } = useMutation(
    {
      mutationFn,
      onSuccess: (data) => {
        queryClient.setQueryData(["enderecos", data.id_cliente], (old) => [
          ...old,
          data,
        ]);
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(
          ["enderecos", data.id_cliente],
          context.prev_data
        );
      },
      onSettled: (data) => {
        queryClient.invalidateQueries({
          queryKey: ["enderecos", data.id_cliente],
        });
      },
    }
  );
  return { createEndereco, isPosttingEndereco };
};

export default useCreateEndereco;
