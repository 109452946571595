import { useEffect } from "react";
import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";
import useDebounce from "../../../../hooks/useDebounce";

const fetchProdutos = async (nome) => {
  const res = await ManjarApi.get("/produtos", { params: { nome: nome } });
  return res?.data.produtos;
};

const useGetProdutos = () => {
  const { value, debouncedValue, handleChange } = useDebounce("", 500);

  const {
    refetch,
    data: produtos,
    isRefetching,
    isLoading,
  } = useQuery({
    queryKey: ["produtos", debouncedValue],
    queryFn: () => fetchProdutos(debouncedValue),
  });

  useEffect(() => {
    refetch();
  }, [debouncedValue]);

  return {
    produtos,
    isGettingProdutos: isLoading || isRefetching,
    searchProduto: value,
    onSearchProduto: handleChange,
  };
};

export default useGetProdutos;
