import "./styles.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from "react";
import CardRight from "../../../../components/Layout/Container/CardRight";
import { useViewProductionContext } from "../../context/ViewProductionContext";
import useGetFatura from "../../api/useGetFatura";

const GenerateFaturaPdf = () => {
  const { idPlano } = useViewProductionContext();
  const { fatura, isGettingFatura } = useGetFatura({ idPlano });
  const dataAtual = new Date();

  // Formata a data para exibição (DD/MM/AAAA)
  const dia = String(dataAtual.getDate()).padStart(2, "0");
  const mes = String(dataAtual.getMonth() + 1).padStart(2, "0");
  const ano = dataAtual.getFullYear();

  const dataFormatada = `${dia}/${mes}/${ano}`;

  // Obtém a hora atual
  const hora = String(dataAtual.getHours()).padStart(2, "0");
  const minutos = String(dataAtual.getMinutes()).padStart(2, "0");

  const horaFormatada = `${hora}:${minutos}`;

  const contentRef = useRef(null);
  const gerarFaturaPDF = () => {
    const pdf = new jsPDF({
      unit: "mm",
    });

    const texto = `
                                        Manjar
      -----------------------------------------------------------------
      Pedido: Nº ${fatura?.id}
      Hora: ${horaFormatada}
      Data: ${dataFormatada}           
      Cliente: ${fatura?.cliente}
      Endereco: ${fatura?.endereco}
      -----------------------------------------------------------------
      Produtos
      -----------------------------------------------------------------
      Plano: ${fatura?.plano}            
      Preço:
      ${fatura?.more_options.map(
        (item) => `
        ---
        R$${item.preco}
        Quantia: ${item.qtd}
        Produto: ${item.produto}
        ---
      `
      )}
      -----------------------------------------------------------------
      Valor Total: R$${fatura?.valor_pago}
      -----------------------------------------------------------------
                                OBRIGADO E BOM PROVEITO!
      -----------------------------------------------------------------
    `;

    pdf.text(texto, 0, 50);
    pdf.save("fatura.pdf");
  };

  return (
    <CardRight isLoading={isGettingFatura}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <h5
          className="user-name  align-self-center"
          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          Fatura
        </h5>
        <button onClick={gerarFaturaPDF} className="small-btn">
          Gerar Fatura
        </button>
      </div>
      <div className="lineSolid" />
      <div className="scroll-card-right">
        <div ref={contentRef}>
          <div className="fatura">
            <span className="fatura-empresa-nome">Manjar</span>
            <div className="lineSolid" />
            <span>
              <span className="fatura-item-bold">Endereco:</span>{" "}
              {fatura?.endereco}
            </span>
            <div className="lineSolid" />
            <span>
              <span className="fatura-item-bold">Pedido:</span> {fatura?.id}
            </span>
            <div className="lineSolid" />
            <div className="display-flex-between-row">
              <span>
                <span className="fatura-item-bold">Data:</span> {dataFormatada}
              </span>
              <span>
                <span className="fatura-item-bold">Hora:</span> {horaFormatada}
              </span>
            </div>
            <span>
              <span className="fatura-item-bold">Cliente:</span>{" "}
              {fatura?.cliente}
            </span>
            <div className="lineSolid" />
            <span className="fatura-empresa-nome">Produtos</span>
            <div className="lineSolid" />
            <div className="display-flex-between-row">
              <span>
                <span className="fatura-item-bold">Plano:</span> {fatura?.plano}
              </span>
              <span>
                <span className="fatura-item-bold">preco:</span> R$
                {fatura?.preco}
              </span>
            </div>
            {fatura?.more_options.map((item) => (
              <div className="item-cell-column">
                ---
                <span className="active-desc">R${item.preco}</span>
                <span>
                  <span className="fatura-item-bold">Quantia:</span> {item.qtd}
                </span>
                <span>
                  <span className="fatura-item-bold">Produto:</span>{" "}
                  <span style={{ fontWeight: "lighter" }}>{item.produto}</span>
                </span>
                ---
              </div>
            ))}
            <div className="lineSolid" />
            <div className="display-flex-between-row">
              <span>
                <span className="fatura-item-bold">Valor Total </span>
              </span>
              <span>R${fatura?.valor_pago}</span>
            </div>
            <div className="lineSolid" />
            <span className="fatura-item-bold text-center">
              OBRIGADO E BOM PROVEITO!
            </span>
            <div className="lineSolid" />
          </div>
        </div>
      </div>
    </CardRight>
  );
};

export default GenerateFaturaPdf;
