/* eslint-disable react/prop-types */

const ConfirmDelection = ({ close, desc, action }) => {
  return (
    <div className="positionAbsolute d-flex align-items-center zindex justify-content-center">
      <div
        style={{ textAlign: "left", border: "1px solid red" }}
        className="bg-white position-relative rounded p-4"
      >
        <h5>Tem certeza de que deseja eliminar este(a) {desc}?</h5>
        <p>Se você eliminar não poderá recuperar.</p>
        <div className="d-flex justify-content-end mt-5">
          <button
            type="button"
            className="btn btn-light border-secondary  mx-3"
            onClick={() => close(false)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-danger boder-1"
            onClick={() => {
              action();
              close(false);
            }}
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelection;
