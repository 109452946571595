import "./styles.scss";
import { Tooltip } from "react-tooltip";
import update from "immutability-helper";
import { fields_entregas } from "../fields";
import Table from "../../../components/Feature/Table";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { refatorarText } from "../../../utils/stringUtils";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { DragHandle } from "@mui/icons-material";

const type = "DragableBodyRow";

const DragableBodyRow = ({
  index,
  moveRow,
  style,
  className,
  children, // Include children prop to render the table cells
  ...restProps
}) => {
  const ref = useRef();
  const dropSpec = {
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? "drop-over-downward" : "drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  };

  const [{ isOver, dropClassName }, drop] = useDrop(dropSpec, [index]);
  const [, drag] = useDrag(
    () => ({
      type,
      item: { index },
      collect: (monitor) => ({ isDragging: monitor.isDragging }),
    }),
    [index]
  );

  drop(drag(ref));

  const rowClass = isOver ? "is-dragging" : "";

  return (
    <tr
      ref={ref}
      className={`draggable-item ${className} ${rowClass}`}
      {...restProps}
    >
      {children}
    </tr>
  );
};

const DragAndDrop = ({ setUpdatedData, entregas, isGettingEntregas }) => {
  const [data, setData] = useState(entregas);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      const new_positions = [];
      const newData = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      newData.map((item, index) =>
        new_positions.push({ new_position: index, id_pedido: item.id })
      );
      setUpdatedData(new_positions);
      setData(newData);
    },
    [data]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        isLoading={isGettingEntregas}
        itemsAtList={data ? data?.length : 0}
        descriptionItemList={"Não há entregas"}
      >
        <thead>
          <ItemsRow>
            {fields_entregas.map((column, index) => (
              <th>{column.label}</th>
            ))}
          </ItemsRow>
        </thead>
        <tbody>
          {data?.map((item, index_entrega) => (
            <DragableBodyRow
              key={index_entrega.id}
              index={index_entrega}
              moveRow={moveRow}
            >
              <td>
                <DragHandle />
              </td>
              <td key={index_entrega} className="item-code fw-bold">
                {item?.position}
              </td>
              <td key={index_entrega} className="fw-bold">
                {item?.cliente}
              </td>
              <td key={index_entrega} id={`endereco${item.id}`}>
                <Tooltip
                  anchorSelect={`#endereco${item.id}`}
                  content={item.endereco}
                />
                {refatorarText(item.endereco, 15)}
              </td>
              <td key={index_entrega}>{item?.entregador}</td>
              <td key={index_entrega}>{item?.hora_entrega}</td>
              <td key={index_entrega}>{item?.hora_saida}</td>
              <td key={index_entrega}>
                <div className="status-entrega">
                  <span className={`${item?.status_entrega.id}`}>
                    {item?.status_entrega.title}
                  </span>
                </div>
              </td>
            </DragableBodyRow>
          ))}
        </tbody>
      </Table>
    </DndProvider>
  );
};

export default DragAndDrop;
