import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";
import { useEffect } from "react";
import useDebounce from "../../../../hooks/useDebounce";

const queryFn = async (nome) => {
  const res = await ManjarApi.get("/entregadores", {
    params: { nome },
  });
  return res?.data.entregadores;
};

const useGetEntregadores = () => {
  const { value, debouncedValue, handleChange } = useDebounce("", 500);

  const {
    refetch,
    data: entregadores,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["entregadores", debouncedValue],
    queryFn: () => queryFn(debouncedValue),
  });

  useEffect(() => {
    refetch();
  }, [debouncedValue]);

  return {
    entregadores,
    isGettingEntregadores: isLoading || isRefetching,
    searchEntregador: value,
    onSearchEntregador: handleChange,
  };
};

export default useGetEntregadores;
