import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCaterias = async () => {
  const res = await ManjarApi.get("/categorias");
  return res?.data?.categorias?.data;
};

const useGetCategorias = () => {
  const { data: categorias, isLoading: isGettingCategorias } = useQuery({
    queryKey: ["categorias"],
    queryFn: fetchCaterias,
  });

  return { categorias, isGettingCategorias };
};

export default useGetCategorias;
