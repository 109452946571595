import React from "react";
import Table from "../../../components/Feature/Table";
import useGetMoreOptions from "../api/useGetMoreOptions";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import CardRight from "../../../components/Layout/Container/CardRight";
import { useViewProductionContext } from "../context/ViewProductionContext";
import NormalBtn from "../../../components/Common/Buttons/NormalBtn";

const CardRightDetalhes = () => {
  const { idPlano, setShowCard } = useViewProductionContext();
  const { options, isGettingOptions } = useGetMoreOptions({ idPlano });

  return (
    <CardRight isLoading={isGettingOptions}>
      <div className="display-flex-between-row">
        <h5 style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Detalhes</h5>
        <button onClick={() => setShowCard(3)} className="small-btn">
          Fatura
        </button>
      </div>
      <div className="lineSolid" />
      <div className="scroll-card-right">
        <Table
          notFoundSmall
          descriptionItemList={"Lista vazia"}
          itemsAtList={
            options?.more_options ? options?.more_options?.length : 0
          }
        >
          {options?.more_options.map((item) => (
            <ItemsRow type={"items-row"}>
              <td style={{ textAlign: "left" }}>
                <div className="item-cell-column">
                  <span className="active-desc">R$ {item.preco}</span>
                  <span>Quantia: {item.qtd}</span>
                  <span>
                    Produto:{" "}
                    <span style={{ fontWeight: "lighter" }}>
                      {item.produto}
                    </span>
                  </span>
                </div>
              </td>
            </ItemsRow>
          ))}
        </Table>
      </div>
    </CardRight>
  );
};

export default CardRightDetalhes;
