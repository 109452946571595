import Table from "../../../components/Feature/Table";
import ItemsRow from "../../../components/Feature/Table/ItemsRow";
import { useGetRotas } from "../api/useGetRotas";
import { fields_rota } from "../fields";

export const Rotas = () => {
  const { rotas, isLoadingRotas } = useGetRotas();
  return (
    <Table
      isLoading={isLoadingRotas}
      itemsAtList={rotas ? rotas.length : 0}
      descriptionItemList={"Não há rotas configuaradas"}
    >
      <ItemsRow type={"items-row-header"}>
        {fields_rota.map((field) => (
          <th>{field.value !== "code" && field.label}</th>
        ))}
      </ItemsRow>
      {rotas?.map((item, index) => (
        <ItemsRow type={"items-row"}>
          <td className="item-code fw-bold">{index + 1}</td>
          <td className="fw-bold">{item.cliente}</td>
          <td className="">{item?.entregador}</td>
          <td className="">{item.bairro}</td>
          <td className="">{item.cidade}</td>
          <td className="">{item.endereco}</td>
        </ItemsRow>
      ))}
    </Table>
  );
};
