import { createContext, useContext, useState } from "react";

const ViewProductsContext = createContext({});

export const ViewProductsContextProvider = ({ children }) => {
  const [idProduto, setIdProduto] = useState();
  const [showCard, setShowCard] = useState(null);

  return (
    <ViewProductsContext.Provider
      value={{ idProduto, setIdProduto, showCard, setShowCard }}
    >
      {children}
    </ViewProductsContext.Provider>
  );
};

export const useViewProductsContext = () => {
  return useContext(ViewProductsContext);
};
