import React from "react";
import Loading from "../../Common/Loading";
import { useRightAnimation } from "../../../hooks/useRightAnimation";

const CardRight = ({ isLoading, children }) => {
  const { animation } = useRightAnimation();

  return isLoading ? (
    <div className="isLoading">
      <Loading />
    </div>
  ) : (
    <div className={`card-right ${animation}`}>{children}</div>
  );
};

export default CardRight;
