import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";
import { useViewBairros } from "../../../context/ViewBairrosContext";
import useCreateBairro from "../../../api/bairro/useCreateBairro";

const AddNeighborhood = () => {
  const [nome, setNome] = useState("");
  const { idCidade } = useViewBairros();
  const { createBairro, isPosttingBairro } = useCreateBairro({ idCidade });

  const handleSave = (close) => {
    if (!nome) {
      toast.error("Preencha todos os campos.");
      return;
    }
    createBairro(
      {
        nome: getFullNameWithInitials(nome),
        idCidade: idCidade,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      smallBtn
      submit={handleSave}
      buttonValue="Adicionar"
      isLoading={isPosttingBairro}
    >
      <div className="form-group my-3">
        <label htmlFor="nome">Nome do bairro:</label>
        <input
          type="text"
          className="form-control"
          id="nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
      </div>
    </ModalForm>
  );
};

export default AddNeighborhood;
