import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchPlanosAssinados = async () => {
  const res = await ManjarApi.get("/plano-assinado");
  return res?.data.planos_assinados;
};

const useGetPlanosAssinados = () => {
  const { data: planos_assinados, isLoading: isGettingPlanosAssinados } =
    useQuery({
      queryKey: ["planos-assinados"],
      queryFn: fetchPlanosAssinados,
    });
  return { planos_assinados, isGettingPlanosAssinados };
};

export default useGetPlanosAssinados;
