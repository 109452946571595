import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const mutationFn = async (data) => {
  const res = await ManjarApi.delete(`/produtos/${data.id}`);
  return res.data;
};

const useDeleteProduto = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: async (data) => {
      console.log(data);
      queryClient.setQueryData(["produtos"], (prev_data) =>
        prev_data?.filter((old) => old.id !== data.id_produto)
      );
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["produtos"], context.prev_data);
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["produtos"],
      });
    },
  });
};

export default useDeleteProduto;
