import Table from "../../../../components/Feature/Table";
import profileImg from "../../../../assets/images/profile.png";
import { removePlano } from "../../../../utils/planoUtils";
import { justifyTenLetter } from "../../../../utils/userUtils";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";
import { fields_clientes } from "../../fields";
import { useViewUsersContext } from "../../context/ViewUsersContext";
import NormalBtn from "../../../../components/Common/Buttons/NormalBtn";
import useChamgeEstadoCliente from "../../api/clientes/useChamgeEstadoCliente";

const Clientes = ({ clientes, isGettingClientes }) => {
  const {
    setIdCliente,
    setShowCard,
    idCliente: cliente,
  } = useViewUsersContext();
  const { changeEstadoCliente, isChangingEstadoCliente } =
    useChamgeEstadoCliente();

  const handleBlock = (id) => {
    setIdCliente(id);
    changeEstadoCliente({ id });
  };

  return (
    <Table
      isLoading={isGettingClientes}
      itemsAtList={clientes ? clientes?.length : 0}
      descriptionItemList={"Não há clientes registradas"}
    >
      <ItemsRow type={"items-row-header"}>
        {fields_clientes.map((field) => (
          <th> {field.value !== "code" && field.label}</th>
        ))}
      </ItemsRow>
      {clientes?.map((item) => (
        <ItemsRow type={"items-row"}>
          <td
            style={{
              borderLeftColor: item?.plano?.cor ?? "",
            }}
            className="item-cell item-code fw-bold"
          >
            {item?.id}
          </td>
          <td>
            <img src={item?.img ? item?.img.url : profileImg} alt="" />
          </td>
          <td className="item-cell" style={{ fontWeight: "bold" }}>
            {justifyTenLetter(item?.nome ?? "")}
          </td>
          <td className="item-cell">{justifyTenLetter(item?.email ?? "")}</td>
          <td className="item-cell">{item?.telefone}</td>
          <td className="item-cell item-tipo">
            {item?.plano?.tipo ?? "Avulso"}
          </td>
          <td>
            <span
              style={{
                color: "white",
                backgroundColor: item?.plano?.cor ?? "",
              }}
              className="name-plan rounded"
            >
              {removePlano(item?.plano?.nome ?? "")}
            </span>
          </td>
          <td>
            <NormalBtn
              value={"Ver"}
              onClick={() => {
                setIdCliente(item.id);
                setShowCard(1);
              }}
            />
          </td>
          <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {item?.estado === "blocked" ? (
                <NormalBtn
                  isLoading={item.id === cliente && isChangingEstadoCliente}
                  backgroundColor={"var(--saudavel)"}
                  value={"Ativar"}
                  onClick={() => handleBlock(item.id)}
                />
              ) : (
                <NormalBtn
                  deleteBtn
                  isLoading={item.id === cliente && isChangingEstadoCliente}
                  descConfirm={"desativar"}
                  msgConfirm={
                    "Ao desativar a conta do cliente ele não terá acesso ao aplicativo!"
                  }
                  btnTitle={"Desativar"}
                  turnOfAccount={true}
                  value={"Desativar"}
                  onClick={() => handleBlock(item.id)}
                />
              )}
            </div>
          </td>
        </ItemsRow>
      ))}
    </Table>
  );
};

export default Clientes;
