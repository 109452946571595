export const getFullNameWithInitials = (fullName) => {
  const words = fullName.split(/\s+/);

  const modifiedName = words
    .map((word) => {
      const firstLetter = word[0].toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return `${firstLetter}${restOfWord}`;
    })
    .join(" ");

  return modifiedName;
};



