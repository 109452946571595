import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SmallLoading from "../../../../../components/Common/SmallLoading";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useCreateEndereco from "../../../api/clientes/useCreateEnderecoCliente";
import useGetCidades from "../../../../Cities/api/cidade/useGetCidades";
import useGetBairros from "../../../../Cities/api/bairro/useGetBairros";

const AddEnderecoForm = () => {
  const { idCliente } = useParams();
  const [cep, setCep] = useState("");
  const [numero, setNumero] = useState("");
  const [idCidade, setIdCidade] = useState();
  const [address, setAddress] = useState("");
  const [idBairro, setIdBairro] = useState();
  const [complemento, setComplemento] = useState(null);
  const { data: dataBairro, isLoading: isGettingBairros } = useGetBairros({
    idCidade,
  });
  const { cidades, isGettingCidades } = useGetCidades();

  const { createEndereco, isPosttingEndereco } = useCreateEndereco();

  const handleSubmit = (close) => {
    if (!cep || !idBairro || !address || !numero) {
      toast.error("Preencha todos os campos 🙏", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    createEndereco(
      {
        cep: cep,
        numero: numero,
        endereco: address,
        id_bairro: idBairro,
        id_cliente: idCliente,
        complemento: complemento,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      submit={handleSubmit}
      buttonValue="Adicionar endereço"
      isLoading={isPosttingEndereco}
    >
      <>
        <div className="mb-3">
          <div className="mb-3">
            <label htmlFor="cep" className="form-label">
              CEP:
            </label>
            <input
              type="text"
              id="cep"
              className="form-control"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />
          </div>
          <label htmlFor="cidade" className="form-label">
            Cidade:
          </label>
          {isGettingCidades ? (
            <div
              style={{ padding: "0.65rem 0" }}
              className="form-control d-flex justify-content-center py-1"
            >
              <SmallLoading />
            </div>
          ) : (
            <select
              id="cidade"
              className="form-select"
              onChange={(e) => setIdCidade(e.target.value)}
            >
              <option>Selecione a cidade</option>
              {cidades?.map((item) => (
                <option value={item.id}>{item.nome}</option>
              ))}
            </select>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="bairro" className="form-label">
            Bairro:
          </label>
          {isGettingBairros ? (
            <div
              style={{ padding: "0.65rem 0" }}
              className="form-control d-flex justify-content-center"
            >
              <SmallLoading />
            </div>
          ) : (
            <select
              id="bairro"
              className="form-select"
              onChange={(e) => setIdBairro(e.target.value)}
            >
              <option>Selecione o bairro</option>
              {dataBairro?.bairros?.map((item) => (
                <option value={item.id}>{item.nome}</option>
              ))}
            </select>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="endereco" className="form-label">
            Endereço:
          </label>
          <input
            type="text"
            id="endereco"
            className="form-control"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="numero" className="form-label">
            Número:
          </label>
          <input
            type="text"
            id="numero"
            className="form-control"
            value={numero}
            onChange={(e) => setNumero(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="complemento" className="form-label">
            Complemento:
          </label>
          <input
            type="text"
            id="complemento"
            className="form-control"
            value={complemento}
            onChange={(e) => setComplemento(e.target.value)}
          />
        </div>
      </>
    </ModalForm>
  );
};

export default AddEnderecoForm;
