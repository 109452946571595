export const hiddenCpf = (cpf) => {
  const firstThreeDigits = cpf.substring(0, 3);
  return firstThreeDigits + "###-###-##";
};

export const justifyTenLetter = (value) => {
  if (value.length < 20) {
    return value;
  }
  const firsTenLetter = value.substring(0, 20);
  return firsTenLetter + "...";
};
