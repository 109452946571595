import Confirm from "../../ConfirmAction/Confirm";
import ConfirmDelection from "../../ConfirmAction/ConfirmDelection";
import SmallLoading from "../../SmallLoading";
import "./styles.scss";
import React, { useState } from "react";

const NormalBtn = ({
  id,
  type,
  value,
  onClick,
  turnOfAccount,
  textColor,
  isLoading,
  className,
  deleteBtn,
  editBtn,
  iconBtn,
  descConfirm,
  msgConfirm,
  btnTitle,
  delectDesc,
  backgroundColor,
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {show &&
        (turnOfAccount ? (
          <Confirm
            action={onClick}
            desc={descConfirm}
            msg={msgConfirm}
            close={setShow}
            btnTitle={btnTitle}
          />
        ) : (
          <ConfirmDelection
            close={setShow}
            desc={delectDesc}
            action={onClick}
          />
        ))}{" "}
      {iconBtn ? (
        <div
          onClick={
            isLoading
              ? () => {}
              : deleteBtn
              ? () => setShow(true)
              : () => onClick()
          }
        >
          {iconBtn}
        </div>
      ) : (
        <button
          onClick={
            isLoading
              ? () => {}
              : deleteBtn
              ? () => setShow(true)
              : () => onClick()
          }
          style={{
            backgroundColor: backgroundColor,
            display: isLoading && "flex",
            alignItems: isLoading && "center",
            justifyContent: isLoading && "center",
          }}
          className={`normal-btn ${type ? type : "normal"} ${className} ${
            editBtn ? "editBtn" : ""
          } ${deleteBtn ? "deleteBtn" : ""}`}
        >
          {value} {isLoading && <SmallLoading />}
        </button>
      )}
    </>
  );
};

export default NormalBtn;
