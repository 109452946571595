import { Delete } from "@mui/icons-material";
import NormalBtn from "../../Common/Buttons/NormalBtn";
import ModalBase from "../../Common/Modal/ModalBase";
import useUploadImmage from "./useUploadImage";

const ModalUploadImage = ({ close, open, upload, entityImage, isLoading }) => {
  const {
    image,
    handleSave,
    isUploading,
    handleUploadLocal,
    handleImageChange,
    handleDeleteImage,
  } = useUploadImmage({ upload });

  return (
    open && (
      <ModalBase setShow={close}>
        <div className="upload-image-container">
          <div className="upload-image-to-upload">
            <div
              disabled={isUploading}
              onClick={() => handleDeleteImage(image)}
              className="icon-btn  delete-icon-btn btn-delete-image"
              style={{ padding: "20px", borderRadius: "100%" }}
            >
              <Delete
                className="icon-btn-icon"
                style={{ width: "35px", height: "35px" }}
              />
            </div>
            <img src={!image ? entityImage : image} alt="" />
          </div>
          <div className="upload-image-bottom">
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <NormalBtn
              isLoading={isLoading}
              disabled={!image && true}
              value={image ? "Salvar" : "Carregar Imagem"}
              onClick={image ? handleSave : handleUploadLocal}
            />
          </div>
        </div>
      </ModalBase>
    )
  );
};

export default ModalUploadImage;
