import "./styles.scss";
import { useState } from "react";
import { PhotoCamera } from "@mui/icons-material";
import ModalUploadImage from "./ModalUploadImage";

function UploadImageContainer({ children, upload, image, isLoading }) {
  const [show, setShow] = useState(false);

  return (
    <div className="container-show-upload">
      <ModalUploadImage
        open={show}
        close={setShow}
        upload={upload}
        entityImage={image}
        isLoading={isLoading}
      />
      <div
        onClick={() => setShow(true)}
        className="photo-camera edit-icon-btn icon-btn"
        style={{ padding: "20px", borderRadius: "100%" }}
      >
        <PhotoCamera
          style={{ width: "22px", height: "22px", color: "white" }}
        />
      </div>
      {children}
    </div>
  );
}

export default UploadImageContainer;
