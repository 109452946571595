import { CloseOutlined } from "@mui/icons-material";

const MasterAlert = ({ show, setShow }) => {
  return (
    show && (
      <div className="positionAbsolute d-flex align-items-center zindex justify-content-center">
        <div
          style={{
            width: "300px",
            display: "flex",
            height: "200px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid red",
          }}
          className="bg-white position-relative rounded p-4 display-item-center"
        >
          <button className="close-btn" onClick={() => setShow(false)}>
            <CloseOutlined
              style={{ height: "20px", width: "20px", color: "white" }}
            />
          </button>
          <h5>A conta principal não pode ser eliminado.</h5>
        </div>
      </div>
    )
  );
};

export default MasterAlert;
