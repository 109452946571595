import "./styles.scss";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import SearchInput from "../../Common/Search";

const MainCard = ({
  children,
  routes,
  headRoute,
  changeRoute,
  className,
  tabs,
  changeTab,
  setChangeTab,
  cardLabel,
  notShowInputSearch,
  headerClassName,
  searchValue,
  onSearchValueChange,
}) => {
  const { user_route } = useParams();
  return (
    <div className={"card-app main-card " + className}>
      <div
        className={`${
          !routes && !tabs && !cardLabel ? "top-card-end" : "top-card"
        } ${headerClassName}`}
      >
        {cardLabel && <div className="card-label">{cardLabel}</div>}
        {(routes || tabs) && (
          <div className="tab-card gap-2">
            {routes &&
              routes.map((item) => (
                <NavLink
                  exact
                  onClick={changeRoute}
                  activeClassName="active"
                  to={`/${headRoute}/${item.route}`}
                  className={`item-tab ${
                    !user_route && item.route === "clientes" && "active"
                  }`}
                >
                  {item.label}
                </NavLink>
              ))}
            {tabs &&
              tabs.map((item) => (
                <div
                  onClick={() => setChangeTab(item.route)}
                  className={`item-tab ${item.route === changeTab && "active"}`}
                >
                  {item.label}
                </div>
              ))}
          </div>
        )}
        {!notShowInputSearch && (
          <SearchInput value={searchValue} onChange={onSearchValueChange} />
        )}
      </div>

      {children}
    </div>
  );
};

export default MainCard;
