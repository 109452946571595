import "./styles.scss";
import React from "react";
import { CloseOutlined } from "@mui/icons-material";
import SelectRotasAndEntregadores from "./SelectRotasAndEntregadores";

const TypesRotasConfig = ({ setShow }) => {
  return (
    <div className="rotas rounded  pt-5 pb-3 px-3">
      <button className="close-btn" onClick={() => setShow(false)}>
        <CloseOutlined
          style={{
            height: "20px",
            width: "20px",
            color: "white",
            borderRadius: "10px",
          }}
        />
      </button>
      <h5 className="lineDashed text-start fw-bold pb-2">Configurar Rotas</h5>
      <div className="mb-4" />
      <SelectRotasAndEntregadores close={() => setShow(false)} />
    </div>
  );
};

export default TypesRotasConfig;
