import React from "react";
import profile from "../../../assets/images/profile.png";
import { postedTime } from "../../../utils/dateUtils";
import { refatorarText } from "../../../utils/stringUtils";

const CardUser = ({ data, active, onClick }) => {
  return (
    <div
      className={`card-user-chat ${active === data.id && "activeCard"}`}
      onClick={onClick}
    >
      <div className="card-user-chat-image-container">
        <img src={data?.img ?? profile} alt="" srcset="" />
      </div>
      <div className="card-user-chat-content">
        <div className="card-user-chat-content-top">
          <span className="user-chat-name">
            {data?.created_at
              ? refatorarText(data.cliente, 15)
              : refatorarText(data.cliente, 25)}
          </span>
          {data?.created_at && (
            <span className="user-chat-data">
              {postedTime(data?.created_at)}
            </span>
          )}
        </div>
        {data?.message && (
          <div className="card-user-chat-content-bottom">
            <span className="chat-message">
              {refatorarText(data?.message, 30)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardUser;
