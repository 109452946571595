import "./featured.scss";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const Featured = () => {
  return (
    <div className="featured">
      <div className="item">
        <span className="title">Resumo das vendas</span>
      </div>
      <div className="item">
        <div className="left-featured">
          <div className="circle-featured circle-today" />
          <div>
            <div className="itemTitle">Hoje</div>
            <span className="featured-earns">R$ 50</span>
          </div>
        </div>
        <div className="itemResult">
          <KeyboardArrowDownIcon fontSize="small" />
          <div className="resultAmount">2 %</div>
        </div>
      </div>
      <div className="item">
        <div className="left-featured">
          <div className="circle-featured circle-yesterday" />
          <div>
            <div className="itemTitle">Ontém</div>
            <span className="featured-earns">R$ 50</span>
          </div>
        </div>
        <div className="itemResult negative">
          <KeyboardArrowDownIcon fontSize="small" />
          <div className="resultAmount">40 %</div>
        </div>
      </div>
      <div className="item">
        <div className="left-featured">
          <div className="circle-featured circle-last-weak" />
          <div>
            <div className="itemTitle">Última semana</div>
            <span className="featured-earns">R$ 50</span>
          </div>
        </div>
        <div className="itemResult positive">
          <KeyboardArrowUpOutlinedIcon fontSize="small" />
          <div className="resultAmount">25 %</div>
        </div>
      </div>
      <div className="item">
        <div className="left-featured">
          <div className="circle-featured circle-las-month" />
          <div>
            <div className="itemTitle">Último mês</div>
            <span className="featured-earns">R$ 50</span>
          </div>
        </div>
        <div className="itemResult positive">
          <KeyboardArrowUpOutlinedIcon fontSize="small" />
          <div className="resultAmount">9 %</div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
