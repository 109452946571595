import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCategoria = async (data) => {
  console.table(data);
  const res = await ManjarApi.post("/cidades", data);
  return res;
};

const useCreateCidade = () => {
  const queryClient = useQueryClient();
  const { mutate: createCidade, isLoading: isPosttingCidade } = useMutation({
    mutationFn: (data) => fetchCategoria(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ["cidades"],
      });
      const previousDocument = queryClient.getQueryData(["cidades"]);
      queryClient.setQueryData(["cidades"], (old) => [...old, data]);

      return { previousDocument };
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["cidades"], context.previousDocument);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["cidades"] });
    },
  });
  return { createCidade, isPosttingCidade };
};

export default useCreateCidade;
