import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import useUpdateEntregador from "../../../api/Entregador/useUpdateEntregador";

const EditEntregadorForm = ({ entregador }) => {
  const [nome, setNome] = useState(entregador.name);
  const { mutate: updateEntregador, isLoading } = useUpdateEntregador();
  const handleSave = (close) => {
    if (!nome) {
      toast.error("Insira o nome🙏", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    updateEntregador(
      {
        body: {
          name: nome,
        },
        id: entregador.id,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
    return;
  };

  return (
    <ModalForm
      editBtn
      submit={handleSave}
      buttonValue="Editar"
      isLoading={isLoading}
    >
      <>
        <div className=" w-100">
          <div className="form-group my-3 w-100">
            <label htmlFor="nome">Nome:</label>
            <input
              type="text"
              className="form-control"
              id="nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
        </div>
      </>
    </ModalForm>
  );
};

export default EditEntregadorForm;
