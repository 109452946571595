import { useState } from "react";

const useDebounce = (initialValue, delay) => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debouncedSetValue = debounce((newValue) => {
    setDebouncedValue(newValue);
  }, delay);

  const handleChange = (newValue) => {
    setValue(newValue);
    debouncedSetValue(newValue);
  };

  return { value, debouncedValue, handleChange };
};

export default useDebounce;
