import React from "react";
import "../production.scss";
import Logo from "../../../components/Common/Logo";

const Navbar = ({ setAbaChange, abaChange, setTitle }) => {
  return (
    <div className={"navbar-production"}>
      <div className="navbar-production-container">
        <span className="logo">
          <Logo />
        </span>
        <div className="d-flex align-items-center">
          <button
            className={`pro-header-btn ${abaChange === "todos" && abaChange}`}
            onClick={() => {
              setAbaChange("todos");
              setTitle("Todos");
            }}
          >
            Todos
          </button>

          <button
            className={`pro-header-btn mx-2 ${
              abaChange === "avulso" && abaChange
            }`}
            onClick={() => {
              setAbaChange("avulso");
              setTitle("Plano Avulso");
            }}
          >
            Plano Avulso
          </button>
          <button
            className={`pro-header-btn ${
              abaChange === "saudavel" && abaChange
            }`}
            onClick={() => {
              setAbaChange("saudavel");
              setTitle("Plano Saudável");
            }}
          >
            Plano Saudável
          </button>
          <button
            className={`pro-header-btn mx-2 ${
              abaChange === "carb" && abaChange
            }`}
            onClick={() => {
              setAbaChange("carb");
              setTitle("Plano Low Carb");
            }}
          >
            Plano Low Carb
          </button>
          <button
            className={`pro-header-btn ${abaChange === "dieta" && abaChange}`}
            onClick={() => {
              setAbaChange("dieta");
              setTitle("Dietas Especiais");
            }}
          >
            Dietas Especiais
          </button>
        </div>
        {localStorage.getItem("user_code") && (
          <button
            className="btn btn-outline-dark"
            onClick={() => {
              localStorage.removeItem("user_code");
              window.location.reload();
            }}
          >
            Sair
          </button>
        )}
      </div>
    </div>
  );
};

export default Navbar;
