import React, { useEffect, useRef } from "react";
import SendMessageInput from "./SendMessageInput";
import { useChooseCliente } from "../context/useChooseCliente";
import SmallLoading from "../../../components/Common/SmallLoading";
import useGetAdminChat from "../api/useGetAdminChat";

const MessageChat = () => {
  const scrollContainerRef = useRef();
  const { clienteId } = useChooseCliente();
  const { adminChat, isGettingAdminChat, refetch } = useGetAdminChat(clienteId);

  useEffect(() => {
    refetch();
  }, [clienteId]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  });

  const scrollToBottom = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  return (
    <div className="message-chat">
      <div className="message-chat-container" ref={scrollContainerRef}>
        {!!isGettingAdminChat ? (
          <div className="align-self-center py-2">
            <SmallLoading />
          </div>
        ) : (
          adminChat?.map((chat, index) => (
            <div
              key={index}
              className={
                chat.sender === "admin"
                  ? "chat-message-admin"
                  : "chat-message-cliente"
              }
            >
              {chat.message}
            </div>
          ))
        )}
      </div>
      <SendMessageInput onClick={scrollToBottom} />
    </div>
  );
};

export default MessageChat;
