import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchEntregas = async () => {
  const res = await ManjarApi.get("/entregas_planos_assinados");
  console.table(res?.data.entregas);
  return res?.data.entregas?.sort((a, b) => a.position - b.position);
};

const useGetEntregas = () => {
  const {
    data: entregas,
    isLoading: isGettingEntregas,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["entregas"],
    queryFn: fetchEntregas,
  });

  return { entregas, isGettingEntregas, refetch, isRefetching };
};

export default useGetEntregas;
