import { useMutation, useQueryClient } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCliente = async (data) => {
  const res = await ManjarApi.patch(`/entregadores/${data.id}`, {
    ...data.body,
  });
  return res.data?.entregador;
};

const useUpdateEntregador = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => fetchCliente(data),
    onSuccess: async (data) => {
      queryClient.setQueryData(["entregadores"], (prev_data) => {
        prev_data?.map((old) => {
          if (old.id === data.id) {
            return data;
          }
          return old;
        });
      });
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(["entregadores"], context.prev_data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["entregadores"] });
    },
  });
};

export default useUpdateEntregador;
