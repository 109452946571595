import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchHomeData = async () => {
  const res = await ManjarApi.get("/home");
  return res?.data;
};

const useGetHomeData = () => {
  const { data: home, isLoading: isGettingHome } = useQuery({
    queryKey: ["home"],
    queryFn: fetchHomeData,
  });

  return { home, isGettingHome };
};

export default useGetHomeData;
