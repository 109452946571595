import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const fetchPlanos = async () => {
  const res = await ManjarApi.get("/planos");
  return res?.data?.planos.data;
};

const useGetPlanos = () => {
  const { data: planos, isLoading: isGettingPlanos } = useQuery({
    queryKey: ["planos"],
    queryFn: fetchPlanos,
  });
  return { planos, isGettingPlanos };
};

export default useGetPlanos;
