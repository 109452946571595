import { useState } from "react";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import ModalForm from "../../../../components/Common/Modal/ModalForm";
import useCreatePlano from "../../api/useCreatePlano";
import ColorPicker from "../../../../components/Common/ColorPicker";

const AddPlan = () => {
  const [nome, setNome] = useState();
  const [desc, setDesc] = useState();
  const [active, setActive] = useState(true);
  const [qtdSemanal, setQtdSemanal] = useState(0);
  const [qtdMensal, setQtdMensal] = useState(0);
  const [priceSemanal, setPriceSemanal] = useState(0);
  const [priceMensal, setPriceMensal] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const { createPlano, isPosttingPlano } = useCreatePlano();

  const handleSave = (close) => {
    if ((!active && (!nome || !desc || !selectedColor)) || !selectedColor) {
      toast.error("Preencha todos os campos e seleciona uma cor.");
      return;
    }

    createPlano(
      {
        nome: nome,
        descricao: desc,
        cor: selectedColor,
        preco_mensal: !active ? priceMensal : null,
        preco_semanal: !active ? priceSemanal : null,
        qtd_refeicoes_mensal: !active ? qtdMensal : null,
        qtd_refeicoes_semanal: !active ? qtdSemanal : null,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      submit={handleSave}
      isLoading={isPosttingPlano}
      buttonValue="Adicionar Plano"
    >
      <>
        <button
          className="checkbtn w-100 d-flex align-items-center rounded"
          onClick={() => {
            return active ? setActive(false) : setActive(true);
          }}
        >
          <Checkbox checked={!active ? true : false} color="primary" />
          Adicionar plano duplo composto por Mensal e Semanal
        </button>

        <div className="form-group my-3">
          <label htmlFor="nome">Nome:</label>
          <input
            type="text"
            className="form-control"
            id="nome"
            name="nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <div className="form-group my-3">
            <label htmlFor="desc">Descrição:</label>
            <textarea
              className="form-control  w-100"
              id="desc"
              name="desc"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group my-3">
              <h5 className="d-flex align-items-center">
                <span className="rounded square"></span>Plano Semanal
              </h5>
            </div>
            <div className="form-group my-3">
              <label htmlFor="price">Quantidade de refeições:</label>
              <input
                type="number"
                className="form-control"
                id="price"
                name="price"
                disabled={active}
                value={qtdSemanal}
                onChange={(e) => setQtdSemanal(parseInt(e.target.value))}
              />
            </div>
            <div className="form-group my-3">
              <label htmlFor="price">Preço:</label>
              <input
                type="number"
                className="form-control"
                id="price"
                name="price"
                disabled={active}
                value={priceSemanal}
                onChange={(e) => setPriceSemanal(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group my-3">
              <h5 className="d-flex align-items-center">
                <span className="rounded square"></span>Plano Mensal
              </h5>
            </div>
            <div className="form-group mt-3 ">
              <label htmlFor="price">Quantidade de refeições:</label>
              <input
                type="number"
                className="form-control"
                id="price"
                name="price"
                disabled={active}
                value={qtdMensal}
                onChange={(e) => setQtdMensal(parseInt(e.target.value))}
              />
            </div>
            <div className="form-group my-3">
              <label htmlFor="price">Preço:</label>
              <input
                type="number"
                className="form-control"
                id="price"
                name="price"
                disabled={active}
                value={priceMensal}
                onChange={(e) => setPriceMensal(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <ColorPicker
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
        </div>
      </>
    </ModalForm>
  );
};

export default AddPlan;
