import "../../styles.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import useCreateCategoria from "../../../api/categoria/useCreateCategoria";
import ModalForm from "../../../../../components/Common/Modal/ModalForm";
import { getFullNameWithInitials } from "../../../../../utils/toUpperCaseUtils";

const AddCategory = () => {
  const [nome, setNome] = useState("");
  const [desc, setDesc] = useState("");
  const { createCategoria, isPosttingCategroia } = useCreateCategoria();

  const handleSave = (close) => {
    if (!nome || !desc) {
      toast.error("Preencha todos os campos.");
      return;
    }

    createCategoria(
      {
        nome: getFullNameWithInitials(nome),
        description: desc,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          close();
        },
        onError() {
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <ModalForm
      smallBtn
      submit={handleSave}
      buttonValue="Adicionar"
      isLoading={isPosttingCategroia}
    >
      <>
        <div>
          <div className="form-group my-3">
            <label htmlFor="nome">Nome:</label>
            <input
              id="nome"
              type="text"
              name="nome"
              value={nome}
              className="form-control"
              placeholder="Insira o nome da categoria"
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="desc">Descrição:</label>
            <textarea
              id="desc"
              name="desc"
              value={desc}
              className="form-control"
              style={{ height: "300px" }}
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Adiciona uma descrição para categoria"
            />
          </div>
        </div>
      </>
    </ModalForm>
  );
};

export default AddCategory;
