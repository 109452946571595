/* eslint-disable react/prop-types */
import Login from "../pages/Login";
import Production from "../pages/Production";

const PrivateRoute = ({ children }) => {
  const url = window.location.href.split("/");

  if (localStorage.getItem("user_code")) {
    return <Production />;
  }

  if (localStorage.getItem("user_code") && url[url.length - 1] !== "producao") {
    return <Production />;
  }

  return localStorage.getItem("token") ? children : <Login />;
};

export default PrivateRoute;
