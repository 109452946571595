import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchCidades = async () => {
  const res = await ManjarApi.get("/cidades");
  return res?.data?.cidades.data ?? [];
};

const useGetCidades = () => {
  const { data: cidades, isLoading: isGettingCidades } = useQuery({
    queryKey: ["cidades"],
    queryFn: fetchCidades,
  });
  return { cidades, isGettingCidades };
};

export default useGetCidades;
