import { toast } from "react-toastify";
import ManjarApi from "./api/ManjarApi";
import ManjarAuthApi from "./api/ManjarAuthApi";

export const setUserLocalStorage = (user) => {
  localStorage.setItem("u", JSON.stringify(user));
};

export const getUserLocalStorage = () => {
  const json = localStorage.getItem("u");
  if (!json) return null;
  return JSON.parse(json) ?? null; //se for uma string vazia retorn null
};

export const LoginRequest = async (email, password) => {
  try {
    const request = await toast.promise(
      ManjarAuthApi.post("login_admin", {
        email: email,
        password: password,
      }),
      { pending: "Carrendango..." }
    );
    if (!request.data) {
      toast.error("E-mail ou password incorreto.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.success("Entrada com Sucesso!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return request.data;
    }
  } catch (error) {
    toast.error(error.response.data.error, {
      position: toast.POSITION.TOP_CENTER,
    });
    return null;
  }
};

export const cpfRequest = async (cpf) => {
  const request = await ManjarApi.get(`checkCpfExists/${cpf}`);
  console.log(request);
  return request.exists;
};

export const emailRequest = async (email) => {
  const request = await ManjarApi.get(`checkEmailExists/${email}`);
  console.log(request);
  return request.exists;
};

export const LoginFuncionario = async (usercode, password) =>
  ManjarApi.post("loginfuncionario", {
    usercode: usercode,
    password: password,
  });

export const logout = async () => {
  try {
    await ManjarApi.post("/logout");
  } catch (error) {}
};
