import { Search } from "@mui/icons-material";

const SearchUserChat = () => {
  return (
    <div className="search-user-chat ">
      <span className="icon-search">
        <Search style={{ color: "gray", height: "20px" }} />
      </span>
      <input type="text" placeholder="Procurar usuário..." />
    </div>
  );
};

export default SearchUserChat;
