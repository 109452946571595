import { useState } from "react";

const useUploadImmage = ({ upload }) => {
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const handleDeleteImage = (file) => {
    if (file || image) {
      setFile(null);
      setImage(null);
    }
  };

  const handleImageChange = (event) => {
    const get_file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", get_file);
    setFile(formData);

    if (get_file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result);
      };

      reader.readAsDataURL(get_file);
    }
  };

  const handleUploadLocal = () => {
    const inputElement = document.getElementById("imageInput");
    inputElement.click();
  };

  const handleSave = () => {
    upload(file);
  };

  return {
    handleDeleteImage,
    handleImageChange,
    handleUploadLocal,
    handleSave,
    isUploading: true,
    image,
    setImage,
  };
};

export default useUploadImmage;
