import "../styles.scss";
import { toast } from "react-toastify";
import useGetProduto from "../../api/produto/useGetProduto";
import productimg from "../../../../assets/images/product.png";
import CardRight from "../../../../components/Layout/Container/CardRight";
import { useViewProductsContext } from "../../context/ViewProductContext";
import EditProduct from "../forms/Produto/EditProduct";
import UploadImageContainer from "../../../../components/Feature/Upload/UploadImageContainer";
import useUloadProdutoImage from "../../api/produto/useUploadProdutoImage";

const CardRightProduto = () => {
  const { idProduto } = useViewProductsContext();
  const { produto, isGettingProduto } = useGetProduto({ idProduto });
  const { mutate: uploadImage, isLoading } = useUloadProdutoImage();

  const handleUpload = (image) => {
    console.log(image);
    uploadImage(
      {
        image,
        imageable_type: "produto",
        imageable_id: idProduto,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <CardRight isLoading={isGettingProduto}>
      <div className="desc-card-right">
        <div className="desc-card-right-container">
          <div className="desc-card-right-content">
            <div className="desc-card-right-content-top">
              <UploadImageContainer
                upload={handleUpload}
                isLoading={isLoading}
                image={produto?.img ? produto?.img.url : productimg}
              >
                <div className="img">
                  <img
                    src={produto?.img ? produto?.img.url : productimg}
                    alt=""
                  />
                </div>
              </UploadImageContainer>
              <span className="desc-card-right-content-top-title">
                {produto?.nome}
              </span>
              <span className="active-desc desc-card-right-content-top-subtle">
                R${produto?.preco}.
              </span>
            </div>
            <div>
              <label className="fw-bold">Categória:</label>
              <span> {produto?.categoria}.</span>
            </div>
            <div>
              <label className="fw-bold">Estoque:</label>
              <span> {produto?.estoque}.</span>
            </div>
            <div>
              <label className="fw-bold">Descrição:</label>
              <br />
              <div className="detailHover rounded">
                <span> {produto?.description}.</span>
              </div>
            </div>
          </div>
          <div className="desc-bottom edit-modal-edit-container">
            <EditProduct produto={produto} />
          </div>
        </div>
      </div>
    </CardRight>
  );
};

export default CardRightProduto;
