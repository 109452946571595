import React from "react";
import Table from "../../../../components/Feature/Table";
import ItemsRow from "../../../../components/Feature/Table/ItemsRow";

const RecentOrders = () => {
  return (
    <Table
      isLoading={false}
      itemsAtList={0}
      descriptionItemList={"Não há pedidos recentes"}
    >
      <ItemsRow type={"items-row-header"}>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </ItemsRow>
      <ItemsRow type={"items-row"}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </ItemsRow>
    </Table>
  );
};

export default RecentOrders;
