import "./styles.scss";
import React from "react";
import Navbar from "../Navbar";
import DescCard from "../../Feature/Cards/DescCard";

const Container = ({
  title,
  children,
  buttons,
  showCard,
  setShowCard,
  descCardContent,
  notShowCloseBtn,
}) => {
  return (
    <div className="container-app">
      <title>{title} | Manjar</title>
      <Navbar show={true} />
      <div
        className={`container-content-app w-100 d-flex  ${
          showCard && "active-app"
        }`}
      >
        <div className={`container-view ${showCard && "active-app"}`}>
          <div className="content-app">
            <div className="header-content-view">
              <h3>{title}</h3>
              {buttons}
            </div>
            {children}
          </div>
        </div>
        {showCard && (
          <DescCard notShowCloseBtn={notShowCloseBtn} setShow={setShowCard}>
            {descCardContent}
          </DescCard>
        )}
      </div>
    </div>
  );
};

export default Container;
