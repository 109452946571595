/* eslint-disable react/prop-types */

const Confirm = ({ close, desc, action, msg, btnTitle }) => {
  return (
    <div className="positionAbsolute d-flex align-items-center zindex justify-content-center">
      <div
        style={{ textAlign: "left", border: "1px solid red" }}
        className="bg-white position-relative rounded p-4"
      >
        <h5>
          Tem certeza de que deseja {btnTitle.toLowerCase()} {desc}?
        </h5>
        <p>{msg}.</p>
        <div className="d-flex justify-content-end mt-5">
          <button
            type="button"
            className="btn btn-light border-secondary  mx-3"
            onClick={() => close(false)}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              action();
              close(false);
            }}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
