import { useState } from "react";
import { toast } from "react-toastify";
import useGetEntregas from "./api/useGetEntregas";
import DragAndDrop from "./components/DragAndDrop";
import Container from "../../components/Layout/Container";
import MainCard from "../../components/Feature/Cards/MainCard";
import NormalBtn from "../../components/Common/Buttons/NormalBtn";
import { useUpdateEntregasPositions } from "./api/useUpdateEntregasOrder";
import { routes_entregas } from "../../routes/routesTabs";
import { Rotas } from "./components/Rotas";
import ConfigRotas from "../../components/Feature/ConfigRotas";

const Deliveries = () => {
  const [updatedData, setUpdatedData] = useState([]);
  const [changeTab, setChangeTab] = useState("entregas");
  const { entregas, isGettingEntregas } = useGetEntregas();
  const { isPending, updateEntregasPositions } = useUpdateEntregasPositions();

  const handleUpdate = () => {
    updateEntregasPositions(
      { positions: updatedData },
      {
        onSuccess() {
          setUpdatedData([]);
          toast.success("Salvo com successo");
        },
        onError() {
          toast.error("Erro ao salvar");
        },
      }
    );
  };

  return (
    <Container
      title={"Entregas"}
      buttons={
        <>
          {changeTab === "entregas" ? (
            <NormalBtn
              value={"Salvar"}
              onClick={
                updatedData?.length !== 0 ? () => handleUpdate() : () => {}
              }
              isLoading={isPending}
            />
          ) : (
            <ConfigRotas />
          )}
        </>
      }
    >
      <MainCard
        changeTab={changeTab}
        tabs={routes_entregas}
        headRoute={"entregas"}
        setChangeTab={setChangeTab}
      >
        {changeTab === "entregas" ? (
          <DragAndDrop
            entregas={entregas}
            setUpdatedData={setUpdatedData}
            isGettingEntregas={isGettingEntregas}
          />
        ) : (
          <Rotas />
        )}
      </MainCard>
    </Container>
  );
};

export default Deliveries;
