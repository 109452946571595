import {
  addDays,
  addWeeks,
  endOfWeek,
  isFriday,
  isMonday,
  isWithinInterval,
  startOfWeek,
  formatDistanceToNow,
} from "date-fns";
import { ptBR } from "date-fns/locale";

export const getMonday = (today) => {
  let nextMonday = startOfWeek(addWeeks(today, 1));
  while (!isMonday(nextMonday)) {
    nextMonday = addDays(nextMonday, 1);
  }

  return nextMonday;
};

export const getFriday = (nextMonday) => {
  let nextFriday = addDays(nextMonday, 4);
  while (!isFriday(nextFriday)) {
    nextFriday = addDays(nextFriday, 1);
  }

  return nextFriday;
};

export const formatDateToBrasileiro = (dateString) => {
  const [year, month, day] = dateString.split("-");
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

export function calcularDataTermino(data, periodo) {
  if (periodo === "Semanal") {
    data.setDate(data.getDate() + 7); // Adiciona 7 dias
  } else if (periodo === "Mensal") {
    data.setMonth(data.getMonth() + 1); // Adiciona 1 mês
  }
  return data.toISOString().split("T")[0]; // Retorna a data no formato 'YYYY-MM-DD'
}

export function compararDiaSemana(data, diaSemanaDesejado) {
  // Mapeia os dias da semana para seus valores numéricos equivalentes
  const diasDaSemana = {
    segunda: 1,
    terca: 2,
    quarta: 3,
    quinta: 4,
    sexta: 5,
  };

  // Divide a string da data para obter dia, mês e ano
  const partesData = data.split("-");
  const dia = parseInt(partesData[0], 10);
  const mes = parseInt(partesData[1], 10) - 1; // Mês em JavaScript é baseado em zero
  const ano = parseInt(partesData[2], 10);

  // Converte para um objeto Date
  const dataObj = new Date(ano, mes, dia);

  // Obtém o dia da semana da data fornecida
  const diaSemanaData = dataObj.getDay();

  // Obtém o valor numérico correspondente ao dia desejado
  const valorDiaSemanaDesejado = diasDaSemana[diaSemanaDesejado.toLowerCase()];

  // Obtém o início e o fim da semana corrente
  const inicioSemana = startOfWeek(new Date(), { weekStartsOn: 1 }); // A semana começa na segunda-feira (1)
  const fimSemana = endOfWeek(new Date(), { weekStartsOn: 1 });

  // Verifica se a data está dentro da semana corrente
  const estaNaSemanaCorrente = isWithinInterval(dataObj, {
    start: inicioSemana,
    end: fimSemana,
  });

  // Compara se os valores são iguais e está na semana corrente
  return diaSemanaData === valorDiaSemanaDesejado && estaNaSemanaCorrente;
}

export const postedTime = (date) => {
  // Exemplo de data de criação (substitua isso pelo seu created_at)
  const createdAt = new Date(date);

  // Formata a diferença em palavras em português
  const tempoPassado = formatDistanceToNow(createdAt, {
    locale: ptBR,
  });

  return tempoPassado;
};
