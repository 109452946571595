import { useQuery } from "react-query";
import ManjarApi from "../../../../services/api/ManjarApi";

const fetchBairros = async (idCidade) => {
  const res = await ManjarApi.get(`/bairros/${idCidade}`);
  return res?.data ?? [];
};

const useGetBairros = ({ idCidade }) => {
  const { data, isLoading: isGetting } = useQuery({
    queryKey: ["bairros", idCidade],
    queryFn: () => fetchBairros(idCidade),
  });
  return { data, isGetting };
};

export default useGetBairros;
