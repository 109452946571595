import {
  Radio,
  Checkbox,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import "./styles.scss";
import Table from "../Table";
import ItemsRow from "../Table/ItemsRow";
import Loading from "../../Common/Loading";
import React, { useEffect, useState } from "react";
import { fetchCidades } from "../../../services/CidadeService";
import { fetchEntregadores } from "../../../services/UserService";
import OptionsConfig from "./OptionsConfig";
import ManjarApi from "../../../services/api/ManjarApi";

const SelectRotasAndEntregadores = ({ close }) => {
  const [value, setValue] = useState("");
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [entregadores, setEntregadores] = useState([]);
  const [checkedBairros, setCheckedBairrros] = useState([]);
  const [checkedEntregadores, setCheckedEntregadores] = useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleAddItems = (item, items, setItems) => {
    if (items.includes(item)) {
      setItems(items.filter((oldItem) => oldItem !== item));
      return;
    }
    setItems([...items, item]);
  };

  const getCidades = async () => {
    setIsLoading(true);
    const cidades = await ManjarApi.get("/cidades_with_bairros");
    const entregadores = await ManjarApi.get("/entregadores");
    console.log(cidades?.data?.cidades, entregadores?.data?.entregadores);
    setEntregadores(entregadores?.data?.entregadores);
    setCidades(cidades?.data?.cidades);
    setValue(cidades?.data?.cidades[0]?.nome);
    setBairros(cidades?.data?.cidades[0]?.bairros);
    setIsLoading(false);
  };

  useEffect(() => {
    getCidades();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="modal-spinner">
          <Loading />
        </div>
      ) : (
        <>
          <div className="rotas-content">
            <div className="rotas-items bairros-Scroll">
              <FormControl>
                <span className="rotas-title">Selecinar Cidade</span>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <Table>
                    {cidades?.map((item) => (
                      <ItemsRow type={"items-row"}>
                        <td className="item-cell">
                          <FormControlLabel
                            color="success"
                            value={item.nome}
                            control={<Radio />}
                            onClick={() => setBairros(item.bairros)}
                          />
                        </td>
                        <td className="py-3">{item.nome}</td>
                      </ItemsRow>
                    ))}
                  </Table>
                </RadioGroup>
              </FormControl>
            </div>
            <div className="rotas-items bairros-Scroll">
              <span className="rotas-title">Selecinar Bairros {value}</span>
              <Table>
                {bairros.map((item) => (
                  <ItemsRow type={"items-row"}>
                    <td className="item-cell">
                      <Checkbox
                        checked={checkedBairros.includes(item.id)}
                        onClick={() =>
                          handleAddItems(
                            item.id,
                            checkedBairros,
                            setCheckedBairrros
                          )
                        }
                        color="success"
                      />
                    </td>
                    <td className="py-3">{item.nome}</td>
                  </ItemsRow>
                ))}
              </Table>
            </div>
            <div className="rotas-items bairros-Scroll">
              <span className="rotas-title">Selecinar Entregadores</span>
              <Table>
                {entregadores?.map((item) => (
                  <ItemsRow type={"items-row"}>
                    <td className="item-cell">
                      <Checkbox
                        checked={checkedEntregadores.includes(item.id)}
                        onClick={() =>
                          handleAddItems(
                            item.id,
                            checkedEntregadores,
                            setCheckedEntregadores
                          )
                        }
                        color="success"
                      />
                    </td>
                    <td className="py-3">{item.name}</td>
                  </ItemsRow>
                ))}
              </Table>
            </div>
          </div>
        </>
      )}
      <div className="select-rota-entregador-buttons">
        <OptionsConfig
          close={close}
          data={{
            bairros: checkedBairros,
            entregadores: checkedEntregadores,
          }}
        />
      </div>
    </>
  );
};

export default SelectRotasAndEntregadores;
