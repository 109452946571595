import { useQuery } from "react-query";
import ManjarApi from "../../../services/api/ManjarApi";

const queryFn = async () => {
  const res = await ManjarApi.get("/chat");
  return res?.data?.chat_clientes;
};

const useGetChatClientes = () => {
  const { data: chatClientes, isLoading: isGettingChatClientes } = useQuery({
    queryKey: ["chat-clientes"],
    queryFn,
  });

  return { chatClientes, isGettingChatClientes };
};

export default useGetChatClientes;
