import "../styles.scss";
import { toast } from "react-toastify";
import profileImg from "../../../../assets/images/profile.png";
import { useViewUsersContext } from "../../context/ViewUsersContext";
import CardRight from "../../../../components/Layout/Container/CardRight";
import { hiddenCpf } from "../../../../utils/userUtils";
import { NavLink } from "react-router-dom";
import useGetClienteById from "../../api/clientes/useGetClienteById";
import EditCliente from "../Forms/Clientes/EditClienteForm";
import NormalBtn from "../../../../components/Common/Buttons/NormalBtn";
import UploadImageContainer from "../../../../components/Feature/Upload/UploadImageContainer";
import useUloadClienteImage from "../../api/clientes/useUploadClienteImage";

const CardRightClientes = () => {
  const { idCliente } = useViewUsersContext();
  const { mutate: uploadImage, isLoading } = useUloadClienteImage();
  const { cliente, isGettingCliente } = useGetClienteById({ idCliente });

  const handleUpload = (image) => {
    uploadImage(
      {
        image,
        imageable_type: "cliente",
        imageable_id: idCliente,
      },
      {
        onSuccess() {
          toast.success("Salvo com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        onError(data) {
          console.log(data);
          toast.error("Erro ao salvar", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
      }
    );
  };

  return (
    <CardRight isLoading={isGettingCliente}>
      <div className="desc-card-right">
        <div className="desc-card-right-container">
          <div className="desc-card-right-content">
            <div className="desc-card-right-content-top">
              <UploadImageContainer
                upload={handleUpload}
                isLoading={isLoading}
                image={cliente?.img ? cliente?.img.url : profileImg}
              >
                <div className="img">
                  <img
                    src={cliente?.img ? cliente?.img.url : profileImg}
                    alt=""
                  />
                </div>
              </UploadImageContainer>
              <span className="desc-card-right-content-top-title">
                {cliente?.nome}
              </span>
              <span className="active-desc desc-card-right-content-top-subtle">
                Refeições: {cliente?.saldo}
              </span>
            </div>
            {cliente?.tipo !== "Normal" && (
              <div className="mt-4">
                <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  {cliente?.tipo}
                </span>
              </div>
            )}
            <div className={cliente?.tipo !== "Normal" ? "" : "mt-4"}>
              <label className="fw-bold">Email:</label>
              <span> {cliente?.email}.</span>
            </div>
            <div>
              <label className="fw-bold">Telefone:</label>
              <span> {cliente?.telefone}.</span>
            </div>
            <div>
              <label className="fw-bold">CPF:</label>
              <span> {hiddenCpf(cliente?.cpf ?? "")}.</span>
            </div>
          </div>
          <div className="desc-bottom">
            <EditCliente cliente={cliente} />
            <NavLink
              className={"w-75"}
              activeClassName="active"
              to={`/usuarios/enderecos/${idCliente}`}
              style={{ textDecorationColor: "none" }}
            >
              <div className="desc-bottom w-100 d-flex edit-modal-edit-container">
                <NormalBtn
                  value={"Endereços"}
                  className={"w-100"}
                  onClick={() => {}}
                />
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </CardRight>
  );
};

export default CardRightClientes;
