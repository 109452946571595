import { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
const ViewUsersContext = createContext({});

export const ViewUsersContextProvider = ({ children }) => {
  const { idCliente: id } = useParams();
  const [idCliente, setIdCliente] = useState(id && id);
  const [showCard, setShowCard] = useState(id ? 1 : null);

  return (
    <ViewUsersContext.Provider
      value={{
        idCliente,
        setIdCliente,
        showCard,
        setShowCard,
      }}
    >
      {children}
    </ViewUsersContext.Provider>
  );
};

export const useViewUsersContext = () => {
  return useContext(ViewUsersContext);
};
